<template>
  <page-layout
    :title="title"
    :sub-title="$t('user_management.view_app_users_page.subtitle')"
    :has-back-button="true"
    :emit-back="true"
    @back="back"
    :fixed-footer="!!totalPages && totalPages > 1"
  >
    <template #header-actions>
      <base-button
        v-if="showSaveChanges"
        :key="key"
        :label="$t('general.button_label.save_changes')"
        type="primary"
        :loading="$refs.routerView.saving"
        :disabled="$refs.routerView.saveDisabled"
        @click="$refs.routerView.handleSaveChanges"
      />
    </template>
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span">
          <i v-if="tab.icon" :class="tab.icon"></i>
          {{ tab.label }}
        </span>
      </el-tab-pane>
    </el-tabs>
    <router-view
      ref="routerView"
      :key="$route.name"
      :totalPages.sync="totalPages"
      :pagination.sync="pagination"
    />
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination
        :key="'pagination_' + $route.name"
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { mapGetters } from 'vuex'
import ViewAppUser from '../mixins/ViewAppUser'
import TabNavigation from '../mixins/TabNavigation'

export default {
  name: 'AppUserViewLayout',
  components: {
    PageLayout
  },
  mixins: [ViewAppUser, TabNavigation],
  props: {
    emitBack: {
      typ: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeName: '',
      key: '',
      showSaveChanges: false,
      isMounted: false,
      totalPages: 0,
      pagination: {
        page: 0,
        size: 15,
        sort: {}
      }
    }
  },
  watch: {
    '$route.name' () {
      this.totalPages = 0
    }
  },
  methods: {
    back () {
      return this.$router.push({
        name: 'ManageAppUsers'
      })
    },
    showButtons () {
      this.showSaveChanges = !!this.$route.meta.showSaveChangesButton
      this.key = this.$key()
    }
  },
  computed: {
    ...mapGetters({
      employeeName: 'user/employeeName'
    }),
    userOspId () {
      return this.$route.params.userOspId || ''
    },
    title () {
      return this.userOspId && this.employeeName(this.userOspId)
        ? this.employeeName(this.userOspId)
        : this.$t('user_management.view_app_users_page.details.title')
    },
    tabs () {
      const baseTabs = [
        {
          label: this.$t('user_management.view_app_users_page.tab_label.budgets'),
          name: 'AppUserBudgetView',
          icon: 'ri-money-euro-circle-line'
        },
        {
          label: this.$t('user_management.view_app_users_page.tab_label.expenses'),
          name: 'AppUserExpensesViewAll',
          icon: 'ri-arrow-left-right-line'
        },
        {
          label: this.$t('user_management.view_app_users_page.tab_label.trip_config'),
          name: 'AppUserTripView',
          icon: 'ri-pin-distance-line'
        },
        {
          label: this.$t('user_management.view_app_users_page.tab_label.trip_reg'),
          name: 'AppUserTripRegistrations',
          icon: 'ri-pin-distance-line'
        },
        {
          label: this.$t('user_management.view_app_users_page.tab_label.groups_and_roles'),
          name: 'AppUserGroupRolesView',
          icon: 'ri-group-line'
        },
        {
          label: this.$t('user_management.view_app_users_page.tab_label.details'),
          name: 'AppUserDetailView',
          icon: 'ri-account-circle-line'
        },
        {
          label: this.$t('user_management.view_app_users_page.tab_label.identification_tokens'),
          name: 'AppUserIdentificationFieldsView',
          icon: 'ri-account-circle-line'
        },
        {
          label: this.$t('general.label.roles'),
          name: 'AppUserViewRoles',
          icon: 'ri-arrow-left-right-line'
        }
      ]
      const ospAdminTabs = [
        {
          label: this.$t('user_management.view_app_users_page.tab_label.parking_history'),
          name: 'AppUserParkingHistory',
          icon: 'ri-history-line'
        }
      ]
      return this.$auth.isOspAdmin() ? baseTabs.concat(ospAdminTabs) : baseTabs
    }
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss"></style>
