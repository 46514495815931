<template>
  <base-section
    :title="$t(`user_management.section.trip_registration_${mode.toLowerCase()}.title`)"
    :key="key"
    class="table-wrapper"
  >
    <slot />
    <el-table
      ref="table"
      :data.sync="tableData"
      :empty-text="emptyText"
      row-class-name="table-row"
      style="width: 100%"
      class="hide-expand-column expand-without-padding"
      default-expand-all
    >
      <el-table-column type="expand" width="1px">
        <template slot-scope="{ row }">
          <div v-if="row.originAddress && row.destinationAddress && row.calculatedKmPerTrip" class="trip-info">
            {{ row.originAddress }} <span>to</span> {{ row.destinationAddress }}<span> </span>{{ row.calculatedKmPerTrip }} km
          </div>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="name"
        :label="$t('user_management.section.trip_registration.table_header.name')"
        min-width="100"
      >
        <template slot-scope="{ row }">
          {{ row.name }}
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="commutingDistance"
        :label="$t('user_management.section.trip_registration.table_header.commutingDistance')"
        min-width="100"
      >
        <template slot-scope="{ row }"> {{ row.kmPerTrip }} km </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="allowance"
        :label="$t('user_management.section.trip_registration.table_header.allowance')"
        min-width="105"
      >
        <template slot-scope="{ row }">
          {{ $currency(calculateCompensation(row.kmPerTrip)) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="validForm"
        :label="$t('user_management.section.trip_registration.table_header.valid_form')"
        min-width="90"
      >
        <template slot-scope="{ row }">
          {{ $dateFormat(row.validFrom) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" class-name="actions-cell" width="200">
        <template slot-scope="scope">
          <base-button
            @click.stop="handleEdit(scope.row)"
            size="small"
            prepend-icon="ri-pencil-line"
            :label="$t('general.button_label.edit')"
          />
          <base-button
            @click.stop="handleDelete(scope.row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
            :label="$t('general.label.remove')"
          >
          </base-button>
        </template>
      </el-table-column>
    </el-table>
    <base-button
      v-if="commuteEnabled"
      @click.stop="dialogFormVisible = true"
      prepend-icon="ri-add-line"
      style="float: right"
      :label="$t('general.label.add')"
    />
    <el-dialog
      :title="$t('user_management.section.trip_registration.dialog.title')"
      :visible.sync="dialogFormVisible"
      @close="dialogClose"
      :key="dialogKey"
    >
      <el-form :model="form" label-position="top" :key="dialogKey">
        <base-currency-input
          v-model="form.kmPerTrip"
          :label="$t('user_management.section.trip_registration.input_label.kmPerTrip')"
          :precision="0"
          :currency="{ prefix: null, suffix: ' km' }"
          :maxWidth="142"
          :validations="$v.form.kmPerTrip"
        />
        <base-datepicker
          v-model="form.validFrom"
          :label="$t('user_management.section.trip_registration.input_label.valid_from')"
          :picker-options="datePickerOptions"
          :maxWidth="142"
          :validations="$v.form.validFrom"
        />
        <base-currency-input
          :key="compensationKey"
          :value="compensation"
          :disabled="true"
          :label="$t('user_management.section.trip_registration.input_label.compensation')"
          :maxWidth="142"
          :precision="4"
        />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <base-button :label="$t('general.cancel')" @click="dialogFormVisible = false" />
        <base-button
          :loading="updating"
          type="primary"
          :label="
            edit
              ? $t('general.button_label.save_changes')
              : $t('user_management.section.trip_registration.button_label.add_commuting_distance')
          "
          @click="handleUpdate"
        />
      </span>
    </el-dialog>
  </base-section>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewAppUser from '../../mixins/ViewAppUser'
import { confirmDefaultOptions } from '../../app/helpers'
import { required } from 'vuelidate/lib/validators'
import TableLoader from '@/mixins/TableLoader'
import { DateTime } from 'luxon'

export default {
  name: 'TripRegistrationsSection',
  mixins: [ViewAppUser, TableLoader],
  props: {
    userOspId: {
      type: String,
      default: ''
    },
    mode: {
      type: String
    },
    data: {
      type: Array
    }
  },
  data () {
    return {
      edit: false,
      compensationKey: '',
      travelData: {},
      key: '',
      dialogKey: '',
      tableData: [],
      dialogFormVisible: false,
      compensation: null,
      updating: false,
      compensationsData: [],
      form: {
        kmPerTrip: null,
        validFrom: null,
        commuteDistanceId: null
      }
    }
  },
  validations: {
    form: {
      kmPerTrip: {
        required
      },
      validFrom: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    travelCompensation () {
      return this.corporateAccount.travelCompensation
    },
    datePickerOptions () {
      return {
        disabledDate: value => {
          if (this.travelData.maximumProcessedDate) {
            return (
              value <
              DateTime.fromSQL(this.travelData.maximumProcessedDate)
                .startOf('day')
                .plus({ days: 1 })
                .toJSDate()
            )
          }
          return false
        }
      }
    },
    commuteCompensationPerKm () {
      if (this.compensationsData && this.compensationsData.length) {
        return this.compensationsData[this.compensationsData.length - 1]
      } else {
        return { compensationPerKm: 0 }
      }
    },
    commuteEnabled () {
      if (this.mode === 'BIKE') {
        return this.travelCompensation.commuteEnabledForBike
      } else if (this.mode === 'CAR') {
        return this.travelCompensation.commuteEnabledForCar
      } else {
        return this.travelCompensation.commuteEnabledForWalking
      }
    },
    emptyText () {
      return this.commuteEnabled
        ? this.$t('user_management.section.trip_registration.empty_text', { firstname: this.employeeFirstName })
        : this.$t(`user_management.section.trip_registration.not_configured_empty_text.${this.mode.toLowerCase()}`)
    }
  },
  watch: {
    'form.kmPerTrip' (v) {
      this.compensation = this.calculateCompensation(v)
      this.compensationKey = this.$key()
    }
  },
  methods: {
    calculateCompensation (kmPerTrip) {
      const { compensationPerKm } = this.commuteCompensationPerKm
      return kmPerTrip * compensationPerKm
    },
    async fetchData (withLoading = true) {
      if (!this.commuteEnabled) {
        return false
      }
      withLoading && this.TableLoader_show()
      try {
        await this.$api.travel.getCommuteDistance(
          {
            userOspId: this.userOspId,
            mobilityMode: this.mode
          },
          {
            onSuccess: response => {
              this.setData(response)
            }
          }
        )
      } catch (e) {
        console.error(e)
      }
      withLoading && this.TableLoader_hide()
      return true
    },
    async fetchTravelData () {
      const month = this.$date.local().month
      const year = this.$date.local().year

      try {
        await this.$api.travel.getTravelData(
          {
            userOspId: this.userOspId,
            month,
            year
          },
          {
            onSuccess: response => {
              this.setTravelData(response)
            }
          }
        )
      } catch (e) {
        console.error(e)
      }
    },
    async fetchCompensationsData () {
      const filter = {
        corporateAccountOspId: this.corporateAccount.ospId,
        mobilityMode: this.mode
      }
      const options = {
        onSuccess: res => {
          this.setCompensationsData(res)
        }
      }
      try {
        await this.$api.compensations.getCommuteTripCompensation(filter, options)
      } catch (e) {
        console.error('e', e)
      }
    },
    dialogClose () {
      this.form.kmPerTrip = null
      this.form.validFrom = null
      this.form.commuteDistanceId = null
      this.edit = false
      this.dialogKey = this.$key()
    },
    handleEdit (data) {
      const { kmPerTrip, validFrom, commuteDistanceId } = data
      this.form.kmPerTrip = kmPerTrip
      this.form.validFrom = validFrom
      this.form.commuteDistanceId = commuteDistanceId
      this.edit = true
      this.dialogFormVisible = true
    },
    handleUpdate () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }
      const form = {
        commuteDistance: {
          ...this.form,
          mobilityMode: this.mode
        },
        userOspId: this.userOspId
      }
      // console.log('form', form)
      return this.updateRequest(form)
    },
    handleDelete ({ commuteDistanceId }) {
      this.$confirm(
        this.$t(`user_management.section.trip_registration.confirm_delete_${this.mode.toLowerCase()}.explanation`),
        this.$t(`user_management.section.trip_registration.confirm_delete_${this.mode.toLowerCase()}.title`),
        confirmDefaultOptions
      )
        .then(() => {
          this.deleteRequest(commuteDistanceId)
        })
        .catch(e => console.log(e))
    },
    updateRequest (form) {
      this.updating = true
      return this.$api.travel.updateCommuteDistance(form, {
        onSuccess: () => {
          this.dialogFormVisible = false
          this.fetchData()
        },
        onFinally: () => {
          this.updating = false
        }
      })
    },
    deleteRequest (commuteDistanceId) {
      this.updating = true
      return this.$api.travel.deleteCommuteDistance(
        { commuteDistanceId },
        {
          onSuccess: () => {
            this.dialogFormVisible = false
            this.fetchData()
          },
          onFinally: () => {
            this.updating = false
          }
        }
      )
    },
    setData (data) {
      this.tableData = data
    },
    setTravelData (data) {
      this.travelData = data
    },
    setCompensationsData (data) {
      this.compensationsData = data
    }
  },
  mounted () {
    this.fetchData()
    this.fetchTravelData()
    this.fetchCompensationsData()
  }
}
</script>

<style lang="scss">
.expand-without-padding {
  .el-table__expanded-cell {
    padding-bottom: 0 !important;
  }
  .trip-info {
    padding-left: 1em;
    padding-bottom: 20px;

    span {
      padding: 0 1em;
    }
  }
}
</style>
