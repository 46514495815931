<template>
  <base-section :is-flex="false" class="table-wrapper" no-padding>
    <h2>{{ $t('user_management.view_app_users_page.tab_label.parking_history') }}</h2>
    <el-form label-position="top">
      <el-row :gutter="24">
        <el-col :span="4">
          <base-datepicker
            v-model="form.dateFrom"
            :label="$t('general.label.start_date')"
          ></base-datepicker>
        </el-col>

        <el-col :span="4">
          <base-datepicker
            v-model="form.dateTo"
            :label="$t('general.label.end_date')"
          ></base-datepicker>
        </el-col>

        <el-col :span="5">
          <base-select
            v-model="form.mrpOspId"
            :label="$t('resources.mobility_select.mrp.label')"
            :items="availableMRPs"
            :clearable="false"
          >
            <template v-slot:option="{ items }">
              <el-option v-for="item in items" :key="item" :value="item" :label="item">
                <div class="filters__select-option">
                  {{ item }}
                </div>
              </el-option>
            </template>
          </base-select>
        </el-col>
      </el-row>
      <div class="checkboxes">
        <div v-for="field in Object.keys(selectedFields)" :key="field">
          <base-checkbox
            :label="field"
            :value="selectedFields[field]"
            @input="v => changeHandler(field, v)"
          />
        </div>
      </div>
    </el-form>

    <el-table
      ref="table"
      :data.sync="tableData"
      :row-class-name="'table-row'"
      :empty-text="$t('general.empty_table')"
      style="width: 100%"
    >
      <el-table-column
        v-for="item in checkedFields"
        :key="item"
        :prop="item"
        :label="item"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ getCellData(row, item) }}
        </template>
      </el-table-column>
    </el-table>
  </base-section>
</template>

<script>
import { debounce, isArray } from 'lodash'
import TableLoader from '@/mixins/TableLoader'
import { DATE_TIME_FORMAT } from '@/app/helpers'

const availableMRPs = ['BeMobile', 'QPark']

export default {
  name: 'AppUserParkingHistory',
  props: ['userOspId', 'totalPages', 'pagination'],
  mixins: [TableLoader],
  data () {
    return {
      tableData: [],
      form: {
        dateFrom: this.$date.local().endOf('day').toISODate(),
        dateTo: this.$date.local().endOf('day').toISODate(),
        mrpOspId: availableMRPs[0]
      },
      availableMRPs,
      allFields: [],
      selectedFields: {
        mrpCustomerId: true,
        sessionUuid: true,
        zoneCode: false,
        city: false,
        startTime: true,
        endTime: true,
        autoStopTime: true,
        totalCostVatIncl: true,
        bookingId: false,
        parkingSessionType: false,
        recordCreationTime: false,
        status: true,
        errorMessage: true,
        licensePlate: false,
        parkingCode: false,
        parkingName: false
      }
    }
  },
  computed: {
    checkedFields () {
      return Object.keys(this.selectedFields).filter(key => this.selectedFields[key])
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.$emit('update:pagination', { ...this.pagination, page: 0 })
        this.fetchParkingHistory()
      }
    },
    'pagination.page': debounce(function () {
      this.fetchParkingHistory()
    }, 200)
  },
  methods: {
    fetchParkingHistory () {
      const requestData = {
        pagination: this.pagination,
        userOspId: this.userOspId,
        ...this.form
      }
      this.TableLoader_show()
      return this.$api.parking.getParkingHistory(requestData, {
        onSuccess: ({ totalPages, content }) => {
          this.setData(content)
          this.$emit('update:totalPages', totalPages)
        },
        onFinally: () => {
          this.TableLoader_hide()
        }
      })
    },
    setData (data) {
      if (isArray(data) && data.length) {
        return this.setTableData(data)
      }
      return this.clearTableData()
    },
    setTableData (data) {
      this.tableData = data
    },
    clearTableData () {
      this.tableData = []
    },
    changeHandler (field, v) {
      this.selectedFields[field] = v
    },
    getCellData (row, item) {
      switch (item) {
        case 'startTime':
        case 'endTime':
        case 'autoStopTime':
        case 'recordCreationTime':
          return row[item] ? this.$dateFormat(row[item], DATE_TIME_FORMAT) : row[item]
        case 'totalCostVatIncl':
          return row[item] !== null ? this.$currency(row[item]) : row[item]
        default:
          return row[item]
      }
    }
  },
  async mounted () {
    this.$emit('update:pagination', { ...this.pagination, size: 10, page: 0 })
    await this.$nextTick()
    this.fetchParkingHistory()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.el-table {
  :deep(.cell.el-tooltip) {
    white-space: normal;
  }
}
.checkboxes {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  border-bottom: 1px solid $gray-200;
  padding: 16px 0;

  .el-form-item {
    margin-bottom: 0;
  }
  :deep(.el-form-item__content) {
    line-height: 28px;
  }
  :deep(.el-checkbox) {
    margin-right: 10px;
  }
}
</style>
