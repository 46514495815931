import api from '@/api'
import Repository from './Repository'
import IdentificationToken from '@/app/models/IdentificationToken'

class IdentificationTokenRepository extends Repository {
  constructor () {
    super(IdentificationToken)
  }

  get (userOspId, options = {}) {
    const optionsData = this.addWrapper(options)
    return api.userIdentificationToken.get({
      userOspId: {
        ospId: userOspId
      }
    }, optionsData)
  }

  add (form, options = {}) {
    return api.userIdentificationToken.add(form, options)
  }

  delete (tokenUUID, options = {}) {
    return api.userIdentificationToken.delete({ tokenUUID }, options)
  }
}

export default IdentificationTokenRepository
