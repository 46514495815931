<template>
  <page-layout :title="$t('user_management.view_app_users_page.add_budget.title')" :has-back-button="true">
    <template #header-actions>
      <base-button
        v-if="types.length"
        :label="$t('user_management.view_app_users_page.add_budget.button_label.add_budget')"
        type="primary"
        :loading="saving"
        @click="handleSaveChanges"
      />
    </template>
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('user_management.view_app_users_page.add_budget.alert_text', { username })" />
    </el-alert>
    <el-form ref="form" :model="model" @submit.prevent.native="null" :key="key" autocomplete="off">
      <base-section v-if="types.length > 1" :title="$t('user_management.section.budgets.title')">
        <template v-for="(type, index) in types">
          <el-col :span="24" :key="index + 'checkbox'">
            <base-radio :label="type.budgetType" :value="budgetType" @input="change" :explanation="type.info">
              {{ type.name }}
            </base-radio>
          </el-col>
        </template>
      </base-section>
      <budget-item-section
        v-if="budgetType && selectedBudget"
        :key="budgetTypeKey"
        :id="budgetType"
        :budget="selectedBudget"
        v-model="model"
        :validations="$v.model"
      />
    </el-form>
  </page-layout>
</template>

<script>
import store from '@/store'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import PageLayout from '../layouts/PageLayout'
import BudgetItemSection from '../components/sections/BudgetItemSection'
import Budget from '../app/models/Budget'

export default {
  name: 'AppUserBudgetAdd',
  components: {
    PageLayout,
    BudgetItemSection
  },
  props: ['userOspId'],
  data () {
    return {
      saving: false,
      budgetType: null,
      model: this.defaultModel(),
      key: '',
      budgetTypeKey: 'budgetTypeKey'
    }
  },
  computed: {
    ...mapGetters({
      userBudgets: 'budget/userBudgets',
      employeeName: 'user/employeeName',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    budgetTypes () {
      return this.corporateAccount.budgetTypes
    },
    allowedBudgetTypes () {
      return this.corporateAccount.allowedBudgetTypes
    },
    types () {
      return (
        this.budgetTypes
          .filter(v => this.allowedBudgetTypes.includes(v.budgetType) && !this.userHasBudget(v))
          .sort(a => {
            return a.budgetType === 'MOBILITY_BUDGET' ? -1 : 0
          }) || []
      )
    },
    selectedBudget () {
      return this.types.find(v => v.budgetType === this.budgetType)
    },
    username () {
      return this.employeeName(this.userOspId)
    }
  },
  watch: {
    budgetType (v) {
      this.model = this.defaultModel()
      this.budgetTypeKey = this.$key()
    }
  },
  methods: {
    ...mapActions({
      createBudget: 'budget/createBudget'
    }),
    change (v) {
      this.budgetType = v
      this.key = this.$key()
    },
    handleSaveChanges () {
      this.$v.model.$touch()
      if (this.$v.model.$invalid) {
        return false
      }
      this.saving = true
      this.model.save({
        onSuccess: () => this.$router.back(),
        onFinally: () => {
          this.saving = false
        }
      })
    },
    userHasBudget (v) {
      return !!this.userBudgets(this.userOspId).find(val => val.type === v.budgetType)
    },
    defaultModel () {
      const data = { ownerOspId: this.userOspId, type: this.budgetType }
      return new Budget({ data })
    }
  },
  validations () {
    if (this.types.length) {
      return {
        model: {
          frequencyAmount: {
            required
          }
        }
      }
    } else {
      return {}
    }
  },
  mounted () {
    if (this.types && this.types[0]) {
      this.budgetType = this.types[0].budgetType
      this.key = this.$key()
    }
  },
  async beforeRouteEnter (to, from, next) {
    const userOspId = to.params.userOspId
    if (userOspId && !store.getters['budget/userBudgets'](userOspId).length) {
      try {
        await store.dispatch('budget/getUserBudgets', { ospId: userOspId })
      } catch (e) {
        if (e.getStatus() !== 404) {
          throw e
        }
      }
    }
    next()
  }
}
</script>

<style lang="scss"></style>
