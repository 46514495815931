<template>
  <base-section
    :title="$t('user_management.section.expense_types.title')"
    :key="key"
  >
    <template v-for="{label, value, explanation} in expensesTypes">
      <el-col :span="24" :key="value">
        <base-radio
          :label="value"
          :value="expensesType"
          @input="change"
          :explanation="explanation"
        >
          {{label}}
        </base-radio>
      </el-col>
    </template>
  </base-section>
</template>
<script>

export default {
  name: 'ExpensesTypesSection',
  props: {
    validations: {
      type: Object
    }
  },
  data () {
    return {
      key: '',
      expensesType: 'single',
      expensesTypes: [
        {
          value: 'single',
          label: this.$t('user_management.view_app_users_page.add_expense.radio_single.label'),
          explanation: this.$t('user_management.view_app_users_page.add_expense.radio_single.explanation')
        },
        {
          value: 'recurring',
          label: this.$t('user_management.view_app_users_page.add_expense.radio_recurring.label'),
          explanation: this.$t('user_management.view_app_users_page.add_expense.radio_recurring.explanation')
        }
      ]
    }
  },
  methods: {
    change (v) {
      this.expensesType = v
      this.key = this.$key()
    }
  },
  watch: {
    expensesType (val) {
      this.$emit('input', val)
    }
  },
  created () {
    if (this.value) {
      this.expensesType = this.value
    }
    this.key = this.$key()
  }
}
</script>

<style scoped>

</style>
