<template>
  <el-form ref="form" :model="form" @submit.prevent.native="null" :key="key" autocomplete="off" id="appUserForm"
           v-loading="loading">
    <base-section
      :title="$t('user_management.add_app_users_page.set_user_groups.user_groups_section_title')"
      :is-flex="true"
    >
      <base-transfer
        ref="transfer"
        :top-titles="[$t('user_management.view_user_groups_page.members.transfer.left_panel_title'), $t('user_management.view_user_groups_page.members.transfer.right_panel_title')]"
        filterable
        :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
        :filter-method="filterMethod"
        v-model="selectedUserGroups"
        :data="transferData"
      >
      </base-transfer>
    </base-section>
    <p class="text-large text-bold">{{
        $t('user_management.add_app_users_page.set_user_groups.products_title', {
          fullname: username
        })
      }}</p>
    <service-resources-section
      :service-resources="serviceResources"
      v-loading="productsLoading"
    ></service-resources-section>
  </el-form>
</template>

<script>
import ViewAppUser from '../mixins/ViewAppUser'
import ServiceResourcesSection from '@/components/sections/ServiceResourcesSection'
import { mapActions, mapGetters } from 'vuex'
import ServiceResourceRepository from '@/app/repositories/ServiceResourceRepository'
import UserGroupRepository from '@/app/repositories/UserGroupRepository'
import { difference, uniq } from 'lodash'
import UserRepository from '@/app/repositories/UserRepository'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'

const serviceResourceRepository = new ServiceResourceRepository()
const userGroupRepository = new UserGroupRepository()
const userRepository = new UserRepository()

export default {
  name: 'AppUserGroupRolesView',
  components: {
    ServiceResourcesSection
  },
  mixins: [ViewAppUser, ConfirmBeforeRouterLeave],
  props: ['userOspId'],
  data () {
    return {
      key: '',
      form: {},
      userData: {},
      selectedUserGroups: [],
      originalData: [],
      serviceResources: [],
      productsLoading: false,
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false
    }
  },
  computed: {
    ...mapGetters({
      userGroups: 'userGroup/all',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    transferData () {
      return this.userGroups.filter(v => {
        return v.data.active && !v.isDefault(this.corporateAccount.extId)
      }).map(v => {
        return {
          key: v.ospId,
          label: v.name,
          disabled: !v.isActive
        }
      })
    },
    defaultServiceResource () {
      return this.userGroups.find(v => v.isDefault(this.corporateAccount.extId))
    },
    addList () {
      return difference(this.selectedUserGroups, this.originalData)
    },
    removeList () {
      return difference(this.originalData, this.selectedUserGroups)
    }
    // dirty: {
    //   get () {
    //     return !!this.addList.length || !!this.removeList.length
    //   },
    //   set (v) {
    //
    //   }
    // }
  },
  watch: {
    selectedUserGroups (newValue) {
      const userGroups = [...newValue.slice(), this.defaultServiceResource.ospId]
      this.productsLoading = true
      serviceResourceRepository.getByUserGroups(uniq(userGroups), {
        onSuccess: response => {
          this.setServiceResources(response)
        },
        onFinally: () => {
          this.productsLoading = false
        }
      })
    },
    dirty (v) {
      this.saveDisabled = !v
    },
    addList: {
      deep: true,
      handler () {
        this.dirty = !!this.addList.length || !!this.removeList.length
      }
    },
    removeList: {
      deep: true,
      handler () {
        this.dirty = !!this.addList.length || !!this.removeList.length
      }
    }
  },
  methods: {
    ...mapActions({
      findAll: 'userGroup/findAll'
    }),
    addGroups () {
      this.addList.forEach(v => {
      })
    },
    async fetchData (withLoading = true) {
      this.loading = withLoading
      await userGroupRepository.getByUser(this.userOspId, {
        onSuccess: response => {
          this.selectedUserGroups = response.map(v => v.ospId)
          this.originalData = response.map(v => v.ospId)
        }
      })
      this.loading = false
    },
    filterMethod (query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
    },
    setServiceResources (resources) {
      this.serviceResources = resources
    },
    async handleSaveChanges () {
      if (!this.dirty) {
        return false
      }
      this.loading = true
      this.saving = true
      const promises = []
      if (this.addList.length) {
        promises.push(userRepository.addGroups(
          { userGroups: this.addList, userOspId: this.userOspId },
          {
            successNotification: this.$t('user_management.view_user_groups_page.success_notification.add')
          }
        ))
      }
      if (this.removeList.length) {
        promises.push(userRepository.removeGroups({ userGroups: this.removeList, userOspId: this.userOspId },
          {
            successNotification: this.$t('user_management.view_user_groups_page.success_notification.remove')
          }))
      }
      await Promise.all(promises)
      await this.fetchData(false)
      this.loading = false
      this.saving = false
    }
  },
  async mounted () {
    this.loading = true
    if (!this.userGroups || !this.userGroups.length) {
      await this.findAll(this.corporateAccount.ospId)
    }
    await this.fetchData(false)
    this.loading = false
  }
}
</script>

<style lang="scss">

</style>
