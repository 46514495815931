import { get } from 'lodash'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewAppUser',
  computed: {
    ...mapGetters({
      employeeName: 'user/employeeName'
    }),
    username () {
      return (this.userOspId && this.employeeName(this.userOspId)) || ''
    },
    employeeFirstName () {
      return this.username.split(' ')[0]
    }
  },
  async beforeRouteEnter (to, from, next) {
    const userOspId = get(to.params, 'userOspId', '')
    if (userOspId && !store.getters['user/employeeName'](userOspId)) {
      try {
        await store.dispatch('user/getEmployeeName', userOspId)
      } catch (e) {
        console.error(e)
      }
    }
    next()
  }
}
