<template>
  <div class="app-user-budgets">
    <el-alert type="info" :closable="false" show-icon>
      <span
        v-html="$t('user_management.view_app_users_page.budgets.alert')"
      ></span>
    </el-alert>
    <base-section
      :title="$t('user_management.section.budgets.title')"
      :is-flex="false"
      class="table-wrapper"
    >
      <el-form>
        <base-checkbox
          :label="
            $t('user_management.view_app_users_page.budgets.show_deactivated')
          "
          v-model="showDeactivated"
        ></base-checkbox>
      </el-form>
      <el-table
        id="appUserBudgetTable"
        ref="appUserBudgetTable"
        :data.sync="tableData"
        :empty-text="
          $t('user_management.view_app_users_page.budgets.table_empty', {
            username
          })
        "
        row-class-name="table-row"
        @row-click="rowClick"
        style="width: 100%"
        class="hide-expand-column"
        default-expand-all
      >
        <el-table-column type="expand" width="1px">
          <template slot-scope="scope">
            <template v-if="scope.row.activationStatus === 'ACTIVE'">
              <base-button
                key="addExpense"
                class="add-item"
                prepend-icon="ri-add-line"
                type="primary"
                :to="{ name: 'AppUserExpensesAdd' }"
                :label="
                  $t(
                    'user_management.view_app_users_page.expenses.button_add_expense'
                  )
                "
              />
              <base-button
                key="addAmount"
                v-if="scope.row.limited"
                class="add-item"
                type="primary"
                @click.stop="
                  $router.push({
                    name: 'AppUserBudgetAmountAdd',
                    params: { budgetOspId: scope.row.ospId },
                    query: { frequencyMethod: scope.row.frequencyMethod }
                  })
                "
                :label="
                  $t(
                    'user_management.view_app_users_page.budgets.button_change_budget_amount'
                  )
                "
              />
            </template>
          </template>
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="type"
          :label="$t('general.label.type')"
        >
          <template slot-scope="scope">
            <div>
              <div class="text-bold">{{ getBudgetName(scope.row) }}</div>
              <div
                class="budget-type-info"
                v-html="scope.row.budgetType.info"
              ></div>
              <div class="plug" v-html="scope.row.budgetType.info"></div>

              <div class="text-extra-small text-muted">
                {{ getBudgetTransferStrategy(scope.row) }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="frequency"
          :label="
            $t(
              'user_management.view_app_users_page.budgets.table_header_top-up'
            )
          "
        >
          <template slot-scope="scope">
            <template v-if="scope.row.active && scope.row.frequency">
              {{
                $t(
                  'user_management.add_app_users_page.set_budget.select_frequency.option_' +
                    scope.row.frequency.toLowerCase()
                )
              }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="currentAmount"
          :label="
            $t(
              'user_management.view_app_users_page.budgets.table_header_top-up_amount'
            )
          "
        >
          <template slot-scope="scope">
            <template v-if="scope.row.active">
              <template v-if="scope.row.frequencyMethod === 'MANUAL'">
                {{
                  $t(
                    'user_management.view_app_users_page.budgets.not_applicable'
                  )
                }}
              </template>
              <template v-else-if="scope.row.frequency">
                {{ $currency(scope.row.frequencyAmount) }}
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="currentAmount"
          :label="
            $t(
              'user_management.view_app_users_page.budgets.table_header_remaining'
            )
          "
        >
          <template slot-scope="scope">
            <template
              v-if="
                scope.row.activationStatus === 'ACTIVE' && scope.row.limited
              "
            >
              {{ $currency(scope.row.currentAmount) }}
              <span
                v-if="
                  scope.row.currentAmountUntil &&
                  scope.row.frequencyMethod !== 'MANUAL'
                "
                class="text-muted"
              >
                {{
                  $t(
                    'user_management.view_app_users_page.budgets.amount_until_date',
                    { date: scope.row.currentAmountUntil }
                  )
                }}
              </span>
            </template>
            <template v-else-if="!scope.row.limited">
              <span>{{
                $t('user_management.view_app_users_page.budgets.unlimited')
              }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column fixed="right" class-name="actions-cell" width="165">
          <template slot-scope="scope">
            <template v-if="scope.row.activationStatus === 'ACTIVE'">
              <base-button
                key="edit"
                @click.stop="handleEdit(scope.row)"
                size="small"
                prepend-icon="ri-pencil-line"
                :label="$t('general.button_label.edit')"
              />
              <base-button
                key="delete"
                @click.stop="handleDelete(scope.row)"
                size="small"
                prepend-icon="ri-delete-bin-2-line"
              >
              </base-button>
            </template>
            <template v-else>
              <base-button
                @click.stop="handleReactivate(scope.row)"
                size="small"
                key="reactivate"
              >
                {{
                  $t(
                    'user_management.view_app_users_page.budgets.table_action_remaining'
                  )
                }}
              </base-button>
            </template>
          </template>
          <!--        <template slot-scope="scope">-->
          <!--          <el-button @click="handleEdit" type="text" size="small">Edit</el-button>-->
          <!--        </template>-->
        </el-table-column>
      </el-table>
      <base-button
        v-if="types.length"
        prepend-icon="ri-add-line"
        :to="{ name: 'AppUserBudgetAdd' }"
        :label="
          $t('user_management.view_app_users_page.budgets.button_add_budget')
        "
      />
    </base-section>
    <app-user-budget-expenses
      v-if="dirtyData.length"
      :userOspId="userOspId"
      :budgets="dirtyData"
    ></app-user-budget-expenses>
    <app-user-budget-timeline
      v-if="dirtyData.length"
      :userOspId="userOspId"
    ></app-user-budget-timeline>
  </div>
</template>

<script>
import ViewAppUser from '../mixins/ViewAppUser'
import { mapActions, mapGetters } from 'vuex'
import { confirmDefaultOptions } from '../app/helpers'
import TableLoader from '@/mixins/TableLoader'
import CancelRequest from '@/mixins/CancelRequest'
import AppUserBudgetExpenses from '@/components/ViewAppUser/AppUserBudgetExpenses'
import AppUserBudgetTimeline from '@/components/ViewAppUser/AppUserBudgetTimeline'

export default {
  name: 'AppUserBudgetView',
  components: { AppUserBudgetExpenses, AppUserBudgetTimeline },
  mixins: [ViewAppUser, TableLoader, CancelRequest],
  props: ['userOspId'],
  data () {
    return {
      requestSource: null,
      showDeactivated: false
    }
  },
  computed: {
    ...mapGetters({
      userBudgets: 'budget/userBudgets',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    budgetTypes () {
      return this.corporateAccount.budgetTypes
    },
    allowedBudgetTypes () {
      return this.corporateAccount.allowedBudgetTypes
    },
    filters () {
      return {
        active: !this.form.inactive,
        search: this.form.search,
        corporateAccountOspId: this.corporateAccount.ospId
      }
    },
    tableData () {
      return (this.dirtyData || []).filter(({ active }) =>
        this.showDeactivated ? true : active
      )
    },
    types () {
      return (
        this.budgetTypes.filter(v => {
          return (
            this.allowedBudgetTypes.includes(v.budgetType) &&
            !this.tableData.find(val => val.type === v.budgetType)
          )
        }) || []
      )
    },
    dirtyData () {
      return this.userBudgets(this.userOspId)
    }
  },
  methods: {
    ...mapActions({
      getUserBudgets: 'budget/getUserBudgets'
    }),
    async fetchData (withLoading = true) {
      withLoading && this.TableLoader_show('appUsersTable')
      try {
        await this.getUserBudgets({
          ospId: this.userOspId,
          options: {
            cancelToken: this.CancelRequest_token()
          }
        })
      } catch (e) {
        console.error(e)
      }
      withLoading && this.TableLoader_hide()
      return true
    },
    findBudgetType (type) {
      return this.budgetTypes.find(v => v.budgetType === type)
    },
    getBudgetName (budget) {
      const type = this.findBudgetType(budget.type)
      return this.$trans(type.budgetTypeName) || ''
    },
    getBudgetTransferStrategy (budget) {
      // console.log(budget)
      if (!budget.transferStrategy || !budget.frequency) {
        return ''
      }
      return this.$t(
        `user_management.view_app_users_page.budgets.frequency_budget_strategy.${budget.transferStrategy.toLowerCase()}_${budget.frequency.toLowerCase()}`
      )
    },
    handleEdit ({ ospId }) {
      this.$router.push({
        name: 'AppUserBudgetEdit',
        params: {
          ...this.$route.params,
          budgetOspId: ospId
        }
      })
      // console.log(ospId)
    },
    async handleDelete (row) {
      this.TableLoader_show()
      try {
        await this.$confirm(
          this.$t(
            'user_management.view_app_users_page.budgets.deactivate_budget.explanation',
            { firstname: this.employeeFirstName }
          ),
          this.$t(
            'user_management.view_app_users_page.budgets.deactivate_budget.title',
            { budget: this.getBudgetName(row).toLowerCase() }
          ),
          {
            ...confirmDefaultOptions,
            confirmButtonText: this.$t(
              'user_management.view_app_users_page.budgets.deactivate_budget.submit',
              { budget: this.getBudgetName(row).toLowerCase() }
            ),
            confirmButtonClass: 'base-button el-button--danger'
          }
        )
        this.requestSource = this.$cancelToken()
        await this.$api.budget.deactivate(row.ospId)
        await this.fetchData(false)
      } catch (e) {
        console.error(e)
      }
      this.TableLoader_hide()
    },
    async handleReactivate (row) {
      try {
        await this.$confirm(
          this.$t(
            'user_management.view_app_users_page.budgets.reactivate_budget.explanation',
            {
              firstname: this.employeeFirstName,
              budget: this.getBudgetName(row).toLowerCase(),
              amount: row.currentAmount,
              transferStrategy: row.transferStrategy
            }
          ),
          this.$t(
            'user_management.view_app_users_page.budgets.reactivate_budget.title',
            { budget: this.getBudgetName(row).toLowerCase() }
          ),
          {
            ...confirmDefaultOptions,
            confirmButtonText: this.$t(
              'user_management.view_app_users_page.budgets.reactivate_budget.submit'
            )
          }
        )
        await this.$api.budget.activate(row.ospId)
        await this.fetchData()
      } catch (e) {}
    },
    rowClick ({ ospId }) {}
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style lang="scss">
#appUserBudgetTable {
  .plug {
    font-size: 13px;
    width: 300%;
    line-height: 18px;
    visibility: hidden;
    margin-bottom: 1.5rem;
  }

  .budget-type-info {
    margin: 5px 0 15px;
    font-weight: normal;
    font-size: 13px;
    color: #7a8998;
    display: inline-block;
    line-height: normal;
    width: 275%;
    position: absolute;
    z-index: 999;
  }

  .el-table__cell {
    vertical-align: bottom;
    padding-bottom: 1rem;
  }
}
</style>
