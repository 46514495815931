<template>
  <base-section :title="$t('user_management.section.account.title')" :key="key">
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          v-if="showLogin"
          :label="$t('general.label.login_name')"
          ref="login"
          prop="login"
          :error="error"
          v-model="form.login"
          :disabled="disabledLogin"
          name="login"
          @keyupEnter="$emit('submit')"
        />
        <base-checkbox
          v-if="showEnableTwoFactorAuth"
          :label="$t('general.label.enable_two_factor')"
          v-model="form.requires2fa"
        />
      </el-col>
      <el-col :span="12">
        <el-form-item :label="$t('general.label.status')" label-position="top">
          <div class="el-input base-empty-input">
            <template v-if="form.active === true">
              <div class="label">
                <i class="ri-user-follow-line active"></i>
                <span>
                  {{ $t('general.active') }}
                </span>
                <template v-if="form.desiredDeactivationDate">
                  <span>
                    &nbsp;
                    {{
                      $t('user_management.view_app_users_page.budgets.amount_until_date', {
                        date: formattedDesiredDate
                      })
                    }}
                  </span>
                  <base-button
                    :label="$t('user_management.view_app_users_page.details.change_date')"
                    size="small"
                    @click="changeDateHandler"
                  />
                </template>
              </div>
              <div class="btn-group">
                <base-button
                  v-if="!form.desiredDeactivationDate"
                  :label="$t('general.button_label.deactivate')"
                  size="small"
                  type="danger"
                  @click="$emit('deactivate')"
                />
                <base-button
                  v-if="showResendCredentials"
                  :label="$t('general.button_label.resend_credentials')"
                  size="small"
                  @click="$emit('sendPincode')"
                />
                <base-button
                  v-if="showUnblockUser"
                  prepend-icon="ri-user-follow-line"
                  :label="$t('general.button_label.unblock_user')"
                  type="primary"
                  size="small"
                  @click="$emit('unblockUser')"
                />
                <base-button
                  v-if="showResetTwoFactorAuth"
                  :label="$t('general.button_label.reset_two_factor_auth')"
                  size="small"
                  @click="$emit('resetTwoFactorAuth')"
                />
              </div>
            </template>
            <template v-else-if="form.active === false">
              <div class="label">
                <i class="ri-user-unfollow-line inactive"></i>
                <span>
                  {{ $t('general.not_active') }}
                </span>

                <div v-if="deactivationDate" style="margin-left: 1rem">
                  {{ $t('user_management.view_app_users_page.details.deactivated_on', { date: deactivationDate }) }}
                </div>

                <template v-if="form.desiredActivationDate">
                  <span>
                    &nbsp;
                    {{
                      $t('user_management.view_app_users_page.budgets.amount_until_date', {
                        date: formattedDesiredDate
                      })
                    }}
                  </span>
                  <base-button
                    :label="$t('user_management.view_app_users_page.details.change_date')"
                    size="small"
                    @click="changeDateHandler"
                  />
                </template>
              </div>
              <div class="btn-group">
                <base-button
                  v-if="!form.desiredActivationDate"
                  :label="$t('general.button_label.activate')"
                  type="success"
                  size="small"
                  @click="$emit('activate')"
                />
                <base-button
                  v-if="showAnonymizeButton"
                  :label="$t('general.button_label.anonymize')"
                  size="small"
                  type="danger"
                  @click="$emit('anonymize')"
                />
                <base-button
                  v-if="showResendCredentials && !showAnonymizeButton"
                  :label="$t('general.button_label.resend_credentials')"
                  size="small"
                  @click="$emit('sendPincode')"
                />
                <base-button
                  v-if="showUnblockUser"
                  prepend-icon="ri-user-follow-line"
                  :label="$t('general.button_label.unblock_user')"
                  type="primary"
                  size="small"
                  @click="$emit('unblockUser')"
                />
                <base-button
                  v-if="showResetTwoFactorAuth"
                  :label="$t('general.button_label.reset_two_factor_auth')"
                  size="small"
                  @click="$emit('resetTwoFactorAuth')"
                />
              </div>
            </template>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </base-section>
</template>

<script>
import { DATE_FORMAT } from '@/app/helpers'

export default {
  name: 'AccountStatusSection',
  data () {
    return {
      key: '',
      error: ''
    }
  },
  props: {
    value: {
      type: Object
    },
    showLogin: {
      type: Boolean,
      default: true
    },
    showResendCredentials: {
      type: Boolean,
      default: true
    },
    showUnblockUser: {
      type: Boolean,
      default: false
    },
    disabledLogin: {
      type: Boolean,
      default: false
    },
    showResetTwoFactorAuth: {
      type: Boolean,
      default: false
    },
    showAnonymizeButton: {
      type: Boolean,
      default: false
    },
    showEnableTwoFactorAuth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
        this.showLoginExistsError(false)
      }
    },
    formattedDesiredDate () {
      if (this.form.activationStatus === 'ACTIVE' && this.form.desiredDeactivationDate) {
        return this.$date.fromISO(this.form.desiredDeactivationDate).toFormat(DATE_FORMAT)
      }
      if (this.form.activationStatus === 'INACTIVE' && this.form.desiredActivationDate) {
        return this.$date.fromISO(this.form.desiredActivationDate).toFormat(DATE_FORMAT)
      }
      return ''
    },
    deactivationDate () {
      return (
        ['END_OF_LIFE', 'INACTIVE'].includes(this.form.activationStatus) &&
        this.form.endOfLifeStartedOn &&
        this.$date.fromISO(this.form.endOfLifeStartedOn).toFormat(DATE_FORMAT)
      )
    }
  },
  methods: {
    showLoginExistsError (show = true) {
      this.error = show ? this.$t('validations.login_exists') : ''
    },
    changeDateHandler () {
      this.$emit('changeDate')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables';
.base-empty-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .label {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    i {
      margin-right: 8px;
      &.active {
        color: $green-500;
      }
      &.inactive {
        color: $red-500;
      }
    }
    button {
      margin-left: 0.5rem;
    }
  }
}
</style>
