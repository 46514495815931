<template>
  <base-section :is-flex="false" class="app-user-expenses table-wrapper">
    <div class="filter-form">
      <h2>{{ $t('general.label.recurring_expenses') }}</h2>
      <base-button
        class="add-item"
        prepend-icon="ri-add-line"
        type="primary"
        :to="{ name: 'AppUserExpensesAdd' }"
        :label="
          $t('user_management.view_app_users_page.expenses.button_add_expense')
        "
      />
      <el-alert type="info" :closable="false" show-icon>
        <span
          v-html="
            $t(
              'user_management.view_app_users_page.recurring_expenses.editable_info_text'
            )
          "
        ></span>
      </el-alert>
    </div>
    <el-table
      ref="table"
      :data.sync="tableData"
      :row-class-name="'table-row'"
      :empty-text="$t('general.empty_table')"
      style="width: 100%"
    >
      <el-table-column
        class-name="text-bold"
        prop="description"
        min-width="150"
        :label="$t('general.input_label.description')"
      >
      </el-table-column>
      <el-table-column
        prop="mobilityModeName"
        min-width="120"
        :label="$t('general.input_label.mobility_provider')"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="budgetName"
        min-width="120"
        :label="$t('general.label.budget')"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="startDate"
        min-width="75"
        :label="
          $t('user_management.view_app_users_page.recurring_expenses.starts_on')
        "
      >
        <template slot-scope="{ row }">
          {{ row.startDate && $dateFormat(row.startDate) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="endDate"
        min-width="75"
        :label="
          $t('user_management.view_app_users_page.recurring_expenses.ends_on')
        "
      >
        <template slot-scope="{ row }">
          {{ row.endDate && $dateFormat(row.endDate) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="recurrenceLabel"
        min-width="120"
        :label="
          $t('user_management.view_app_users_page.recurring_expenses.is_added')
        "
      >
      </el-table-column>
      <el-table-column
        prop="priceVatIncl"
        :label="
          $t('user_management.view_app_users_page.recurring_expenses.amount')
        "
        min-width="135"
      >
        <template slot-scope="{ row }">
          {{ $currency(row.priceVatIncl) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" class-name="actions-cell" width="135">
        <template slot-scope="{ row }">
          <template v-if="row.editable">
            <base-button
              @click.stop="handleDeleteRecurrentExpense(row)"
              size="small"
              prepend-icon="ri-delete-bin-2-line"
            >
            </base-button>
            <base-button
              @click.stop="handleEdit(row)"
              size="small"
              prepend-icon="ri-pencil-line"
              :label="$t('general.button_label.edit')"
            />
          </template>
          <template v-else>
            <base-button
              @click.stop="handleEdit(row)"
              size="small"
              prepend-icon="ri-eye-line"
            />
          </template>
        </template>
      </el-table-column>
    </el-table>
  </base-section>
</template>

<script>
import TableLoader from '@/mixins/TableLoader'

export default {
  name: 'AppUserRecurrentExpences',
  mixins: [TableLoader],
  props: ['userOspId'],
  data () {
    return {
      tableData: []
    }
  },
  computed: {},
  methods: {
    async fetchData (withLoading = true) {
      const loading = withLoading && this.loading()
      try {
        await this.$api.recurringExternalPurchase.findByUser(this.userOspId, {
          onSuccess: response => this.setData(response)
        })
      } catch (e) {
        console.error(e)
      }
      withLoading && loading.close()
    },
    loading () {
      return this.TableLoader_show()
    },
    handleEdit (event) {
      this.$emit('handleEdit', event)
    },
    setData (data) {
      this.tableData = data
    },
    handleDeleteRecurrentExpense (expense) {
      const loading = this.loading()
      const options = {
        successNotification: this.$t(
          'user_management.section.expenses.dialog.success_notification'
        ),
        onSuccess: () => {
          this.fetchData()
          this.$emit('handleChange')
        },
        onFinally: () => {
          loading.close()
        }
      }
      this.$api.recurringExternalPurchase
        .delete(
          {
            ospId: expense.ospId
          },
          options
        )
        .catch(e => console.error('Error: ', e.getMessage()))
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style lang="scss"></style>
