<template>
  <base-section :title="$t('user_management.section.identification.title')">
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          disabled
          :label="$t('general.label.external_id')"
          ref="extId"
          prop="extId"
          v-model="form.extId"
          name="extId"
        />
      </el-col>
      <el-col :span="12">
        <base-input
          :label="$t('general.label.employee_number')"
          ref="employeeNumber"
          prop="employeeNumber"
          v-model="form.employeeNumber"
          name="employeeNumber"
        />
      </el-col>
      <el-col :span="12">
        <base-input
          :label="$t('general.label.contract_number')"
          ref="contractNumber"
          prop="contractNumber"
          v-model="form.contractId"
          name="contractNumber"
        />
      </el-col>
      <el-col v-if="form.pluginEmail" :span="12">
        <base-input
          disabled
          :label="$t('general.label.plugin_email')"
          ref="pluginEmail"
          prop="pluginEmail"
          v-model="form.pluginEmail"
          name="pluginEmail"
        />
      </el-col>
    </el-row>
  </base-section>
</template>

<script>
export default {
  name: 'IdentificationSection',
  data () {
    return {}
  },
  props: {
    value: {
      type: Object
    },
    userOspId: {
      type: String,
      default: null
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.token-divider {
  display: block;
  height: 30px;
}

.d-flex-container {
  display: flex;
  width: 100%;
  max-width: 250px;
  justify-content: space-between;
  position: relative;
  align-items: center;
  & .el-form-item {
    margin-bottom: 0;
  }
  & .el-button {
    position: relative;
    bottom: -12px;
  }
}
.table-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #282c36;
  margin-top: 30px !important;
}
</style>
