<template>
  <el-form
    ref="form"
    :model="form"
    @submit.prevent.native="null"
    :key="key"
    autocomplete="off"
    id="appUserForm"
    v-loading="loading"
  >
    <account-status-section
      v-model="form.userData"
      id="accountSection"
      ref="accountSection"
      :disabledLogin="true"
      :showUnblockUser="isShowUnblockUserButton"
      :showAnonymizeButton="isShowAnonymizeButton"
      @deactivate="showDeactivationModal"
      @activate="activate"
      @sendPincode="sendPincode"
      @submit="handleSaveChanges"
      @unblockUser="handleUnblockUser"
      @anonymize="anonymize"
      @changeDate="changeDate"
    />
    <personal-section
      v-model="form.userData"
      id="personalSection"
      ref="personalSection"
      :extra-button="false"
      :show-extra="true"
      @submit="handleSaveChanges"
    />
    <identification-section v-model="form.userData" :user-osp-id="userOspId" />
    <address-section
      id="homeAddressSection"
      v-model="form.userData"
      ref="homeAddressSection"
      type="home"
      @submit="handleSaveChanges"
    />
    <address-section
      v-model="form.userData"
      id="workAddressSection"
      type="work"
      ref="workAddressSection"
      @submit="handleSaveChanges"
    />
    <change-status-modal ref="changeStatusModal"></change-status-modal>
  </el-form>
</template>

<script>
import PersonalSection from '../components/sections/PersonalSection'
import AddressSection from '../components/sections/AddressSection'
import AccountStatusSection from '../components/sections/AccountStatusSection'
import { confirmDefaultOptions } from '../app/helpers'
import { get } from 'lodash'
import ViewAppUser from '../mixins/ViewAppUser'
import { mapActions, mapGetters } from 'vuex'
import ConfirmBeforeRouterLeave from '../mixins/ConfirmBeforeRouterLeave'
import IdentificationSection from '@/components/sections/IdentificationSection'
import ChangeStatusModal from '@/components/ViewAppUser/ChangeStatusModal'

export default {
  name: 'AppUserDetailView',
  components: {
    AccountStatusSection,
    PersonalSection,
    IdentificationSection,
    AddressSection,
    ChangeStatusModal
  },
  mixins: [ViewAppUser, ConfirmBeforeRouterLeave],
  props: ['userOspId'],
  data () {
    return {
      form: {
        userData: {},
        ospId: null
      },
      key: '',
      loading: false,
      saving: false,
      saveDisabled: false,
      dirty: false
    }
  },
  computed: {
    ...mapGetters({
      userBudgets: 'budget/userBudgets'
    }),
    username () {
      return get(this.form, ['userData', 'firstname'], '') + ' ' + get(this.form, ['userData', 'lastname'], '')
    },
    isShowUnblockUserButton () {
      return this.form.userData.failedLoginCount >= 3
    },
    userBudgetsData () {
      return this.userBudgets(this.userOspId)
    },
    isB2C () {
      return this.userBudgetsData.length === 1 && this.userBudgetsData[0].type === 'PRIVATE_BUDGET'
    },
    isShowAnonymizeButton () {
      return this.$auth.isOspAdmin() && this.form.userData.activationStatus === 'INACTIVE'
    }
  },
  methods: {
    ...mapActions({
      setEmployeeName: 'user/setEmployeeName',
      getUserBudgets: 'budget/getUserBudgets'
    }),
    async activate () {
      try {
        await this.$confirm(
          this.$t('user_management.view_app_users_page.details.confirm_activate'),
          confirmDefaultOptions
        )
        this.loading = true
        await this.$api.user.activate(this.userOspId)
        await this.fetchData()
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    showDeactivationModal () {
      const endOfMessage = this.isB2C ? '_b2c' : ''
      const title = this.$t('user_management.view_app_users_page.details.confirm_deactivate_title', {
        username: this.username
      })
      const description = this.$t(
        'user_management.view_app_users_page.details.confirm_deactivate_explanation' + endOfMessage,
        {
          username: this.username
        }
      )
      this.$refs.changeStatusModal.show({
        title,
        description,
        buttonType: 'danger',
        buttonLabel: this.$t('user_management.view_app_users_page.details.confirm__submit_deactivate', {
          username: this.username
        }),
        cb: this.deactivationModalHandler,
        isDeactivation: true,
        swapLabel: true
      })
    },
    changeDate () {
      if (this.form.userData.activationStatus === 'ACTIVE' && this.form.userData.desiredDeactivationDate) {
        this.$refs.changeStatusModal.show({
          isDeactivation: true,
          date: this.form.userData.desiredDeactivationDate,
          cb: date => {
            if (date !== this.form.userData.desiredDeactivationDate) {
              this.form.userData.desiredDeactivationDate = date
              this.handleSaveChanges()
            }
          }
        })
      }
      if (this.form.userData.activationStatus === 'INACTIVE' && this.form.userData.desiredActivationDate) {
        this.$refs.changeStatusModal.show({
          swapLabel: true,
          date: this.form.userData.desiredActivationDate,
          cb: date => {
            if (date !== this.form.userData.desiredActivationDate) {
              this.form.userData.desiredActivationDate = date
              this.handleSaveChanges()
            }
          }
        })
      }
    },
    deactivationModalHandler (date) {
      if (date) {
        this.form.userData.desiredDeactivationDate = date
        return this.handleSaveChanges()
      }
      return this.deactivate()
    },
    async deactivate () {
      this.loading = true
      try {
        await this.$api.user.deactivate(this.userOspId)
        await this.fetchData()
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    async sendPincode () {
      try {
        await this.$confirm(
          this.$t('user_management.view_app_users_page.details.confirm_send_pincode'),
          confirmDefaultOptions
        )
        this.loading = true
        await this.$api.user.sendPincode(this.userOspId, {
          successNotification: this.$t('user_management.view_app_users_page.details.send_pincode_success')
        })
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async anonymize () {
      try {
        await this.$confirm(
          this.$t('user_management.view_app_users_page.details.confirm_anonymize_explanation', {
            username: this.username
          }),
          this.$t('user_management.view_app_users_page.details.confirm_anonymize_title', {
            username: this.username
          }),
          {
            ...confirmDefaultOptions,
            confirmButtonText: this.$t('user_management.view_app_users_page.details.confirm_submit_anonymize', {
              username: this.username
            }),
            confirmButtonClass: 'base-button el-button--danger'
          }
        )
        this.loading = true
        await this.$api.user.anonymize(this.userOspId)
        this.$router.push({ name: 'ManageAppUsers' })
      } catch (e) {
        console.log(e)
      }
    },
    fetchData (options) {
      this.loading = true
      return this.$api.user.getUser(this.userOspId, {
        onSuccess: response => {
          options?.onSuccess && options.onSuccess(response)
          this.form = response
          this.saveDisabled = false
          this.key = this.$key()
          setTimeout(() => {
            this.dirty = false
          }, 100)
        },
        onFinally: () => {
          this.loading = false
        }
      })
    },
    handleSaveChanges () {
      if (!this.validate()) {
        return false
      }
      this.saving = true
      if (this.form.userData.employeeNumber === '') {
        this.form.userData.employeeNumber = null
      }
      if (this.form.userData.iban === '') {
        this.form.userData.iban = null
      }
      this.$api.user
        .updateUser(
          {
            ospId: this.form.ospId,
            userData: {
              ...this.form.userData
            }
          },
          {
            successNotification: this.$t('user_management.view_app_users_page.details.success_notification_save'),
            onSuccess: () => {
              this.fetchData({
                onSuccess: data => this.setEmployeeName(data)
              })
            },
            onFinally: () => {
              this.saving = false
              this.dirty = false
            }
          }
        )
        .catch(e => {
          console.log(e)
          this.dirty = false
          this.saving = false
        })
    },
    validate () {
      this.$refs.personalSection.$v.form.$touch()
      this.$refs.homeAddressSection.$v.form.$touch()
      this.$refs.workAddressSection.$v.form.$touch()
      if (this.$refs.personalSection.$v.form.$invalid) {
        this.$scrollTo('#personalSection')
        return false
      }
      if (this.$refs.homeAddressSection.$v.form.$invalid) {
        this.$scrollTo('#homeAddressSection')
        return false
      }
      if (this.$refs.workAddressSection.$v.form.$invalid) {
        this.$scrollTo('#workAddressSection')
        return false
      }
      return true
    },
    async allowRouterLeave () {
      if (!this.dirty) {
        return true
      }
      try {
        await this.$confirm(this.$t('user_management.view_app_users_page.details.confirm_exit'), {
          ...confirmDefaultOptions,
          confirmButtonText: this.$t('user_management.view_app_users_page.details.confirm_exit_submit')
        })
        this.dirty = false
        return true
      } catch (e) {
        return false
      }
    },
    handleUnblockUser () {
      this.$api.user.unblock(this.form.ospId, {
        onSuccess: () => {
          this.fetchData()
        },
        successNotification: this.$t('general.notification.user.userUnblock')
      })
    },
    fetchUsersBudgets (ospId) {
      this.getUserBudgets({ ospId })
    }
  },
  async mounted () {
    await this.fetchUsersBudgets(this.userOspId)
    await this.fetchData()
    this.$watch('form', {
      deep: true,
      handler () {
        this.dirty = true
        this.saveDisabled = false
      }
    })
  }
}
</script>

<style lang="scss"></style>
