// import { trans } from '../../helpers'
import { DateTime } from 'luxon'
import store from '@/store'

import Model from '@/app/models/Model'
import IdentificationTokenRepository from '@/app/repositories/IdentificationTokenRepository'
import { DATE_TIME_ISO, DATE_FORMAT, trans } from '@/app/helpers'

class IdentificationToken extends Model {
  #repository = null
  // validTo: "2019-08-07T12:00:57+0000"
  // validTo = null
  // defaultLabel = ''
  // identificationFields = []
  // tokenType = ''
  // userOspId = {
  //   ospId: null
  // }
  deleted = false
  //
  // static TOKEN_TYPES = {
  //   MOBIB: 'MOBIB',
  //   LICENCE_PLATE: 'LICENCE_PLATE',
  //   BLUEBIKE_NUMBER: 'BLUEBIKE_NUMBER',
  //   SWAPFIETS_NUMBER: 'SWAPFIETS_NUMBER'
  // }
  //
  // static FIELD_TYPES = {
  //   MOBIB_CARD_NUMBER: 'MOBIB_CARD_NUMBER',
  //   MOBIB_CHIP_ID: 'MOBIB_CHIP_ID',
  //   LICENCE_PLATE: 'LICENCE_PLATE',
  //   SWAPFIETS_NUMBER: 'SWAPFIETS_NUMBER',
  //   BLUEBIKE_NUMBER: 'BLUEBIKE_NUMBER'
  // }

  constructor (object = {}) {
    super(object)
    this.#repository = new IdentificationTokenRepository()
  }

  isValid () {
    return (this.validTo === null || (DateTime.fromISO(this.validTo) > DateTime.local())) && !this.deleted
  }

  get name () {
    return trans(this.label)
  }

  // get number () {
  //   return this.identificationFields[0].data
  // }
  //
  // set number (v) {
  //   this.identificationFields[0].data = v
  // }

  get validFromDate () {
    if (this.validFrom) {
      return DateTime.fromISO(this.validFrom).toFormat(DATE_FORMAT)
    }
    return this.validFrom
  }

  get validToDate () {
    if (this.validTo) {
      return DateTime.fromISO(this.validTo).toFormat(DATE_FORMAT)
    }
    return this.validTo
  }

  get getTokenType () {
    return this.tokenType
  }

  getFieldToken (fieldType) {
    const identificationField = this.identificationFields.find(v => v.type === fieldType)
    return identificationField ? identificationField.data : ''
  }

  static create (userOspId, tokenType) {
    const enecoTokenType = 'ENECO_CHARGING'
    const enecoFieldType = 'CARD_NUMBER'
    if (tokenType === enecoTokenType) {
      tokenType = enecoFieldType
    }
    const fieldTypes = store.getters['identificationTokens/fieldTypes'].filter(v => v.indexOf(tokenType) === 0)
    const data = {
      tokenUUID: null,
      label: {
        defaultValue: '',
        labelTranslations: []
      },
      userOspId: {
        ospId: userOspId
      },
      tokenType: tokenType === enecoFieldType ? enecoTokenType : tokenType,
      identificationFields: fieldTypes.map(type => ({
        data: '',
        type
      }))
    }
    return new IdentificationToken(data)
  }

  static newLicensePlate (userOspId) {
    const data = {
      validTo: null,
      label: {
        defaultValue: ''
      },
      identificationFields: [{
        data: '',
        identificationFieldUUID: null,
        tokenUUID: null,
        type: IdentificationToken.FIELD_TYPES.LICENCE_PLATE,
        userOspId: { ospId: userOspId }
      }],
      tokenType: IdentificationToken.TOKEN_TYPES.LICENCE_PLATE,
      userOspId: {
        ospId: userOspId
      }
    }
    return new IdentificationToken(data)
  }

  static newMobibCard (userOspId, mobibType) {
    const data = {
      validTo: null,
      label: {
        defaultValue: ''
      },
      identificationFields: [{
        data: '',
        identificationFieldUUID: null,
        tokenUUID: null,
        type: mobibType,
        userOspId: { ospId: userOspId }
      }],
      tokenType: IdentificationToken.TOKEN_TYPES.MOBIB,
      userOspId: {
        ospId: userOspId
      }
    }
    return new IdentificationToken(data)
  }

  static newSwapfiets (userOspId) {
    const data = {
      validTo: null,
      label: {
        defaultValue: ''
      },
      identificationFields: [{
        data: '',
        identificationFieldUUID: null,
        tokenUUID: null,
        type: IdentificationToken.FIELD_TYPES.SWAPFIETS_NUMBER,
        userOspId: { ospId: userOspId }
      }],
      tokenType: IdentificationToken.TOKEN_TYPES.SWAPFIETS_NUMBER,
      userOspId: {
        ospId: userOspId
      }
    }
    return new IdentificationToken(data)
  }

  static newBlueBikeNumber (userOspId) {
    const data = {
      validTo: null,
      label: {
        defaultValue: ''
      },
      identificationFields: [{
        data: '',
        identificationFieldUUID: null,
        tokenUUID: null,
        type: IdentificationToken.FIELD_TYPES.BLUEBIKE_NUMBER,
        userOspId: { ospId: userOspId }
      }],
      tokenType: IdentificationToken.TOKEN_TYPES.BLUEBIKE_NUMBER,
      userOspId: {
        ospId: userOspId
      }
    }
    return new IdentificationToken(data)
  }

  add (options = {}) {
    const form = {
      defaultLabel: this.label.defaultValue,
      translations: this.label.labelTranslations,
      identificationFields: this.identificationFields.filter(({ data }) => !!data),
      tokenType: this.tokenType,
      userOspId: this.userOspId,
      validFrom: DateTime.local().toFormat(DATE_TIME_ISO)
    }
    return this.#repository.add(form, options)
  }

  delete (options = {}) {
    return this.#repository.delete(this.tokenUUID, options)
  }
}

export default IdentificationToken
