<template>
  <el-dialog id="activationDialog" :title="content.title" :visible="visible" @close="close">
    <div v-if="content.description" v-html="content.description"></div>
    <account-activation-section
      :key="key"
      v-model="content.date"
      :picker-options="datePickerOptions"
      :deactivation="content.isDeactivation"
      :swap-label="content.swapLabel"
    />
    <div slot="footer" class="dialog-footer">
      <base-button :label="$t('general.cancel')" @click="close"></base-button>
      <base-button
        :type="content.buttonType"
        v-loading="saving"
        :label="content.buttonLabel"
        @click="clickHandler"
      ></base-button>
    </div>
  </el-dialog>
</template>

<script>
import AccountActivationSection from '../sections/AccountActivationSection'

export default {
  name: 'ChangeStatusModal',
  components: {
    AccountActivationSection
  },
  data () {
    return {
      key: null,
      visible: false,
      saving: false,
      content: this.getDefaultContent()
    }
  },
  methods: {
    show (data = null) {
      if (data) {
        this.content = { ...this.getDefaultContent(), ...data }
      }
      this.visible = true
      this.key = this.$key()
    },
    close () {
      this.visible = false
      this.content = this.getDefaultContent()
    },
    getDefaultContent () {
      return {
        title: '',
        description: '',
        buttonType: 'primary',
        buttonLabel: this.$t('general.confirm'),
        cb: () => {},
        isDeactivation: false,
        date: null,
        swapLabel: false
      }
    },
    clickHandler () {
      this.content.cb(this.content.date)
      this.close()
    }
  },
  computed: {
    datePickerOptions () {
      return {
        disabledDate: value =>
          this.content.isDeactivation
            ? value < this.$date.local().startOf('day')
            : value < this.$date.local().plus({ days: 1 }).startOf('day')
      }
    }
  }
}
</script>

<style lang="scss">
#activationDialog {
  .el-dialog__body {
    word-break: break-word;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
</style>
