<template>
  <el-checkbox
    class="homework-registration"
    :label="$t('user_management.section.hw_registration.label')"
    v-model="enabled"
    @change="handleChange($event)"
  ></el-checkbox>
</template>

<script>
export default {
  data () {
    return {
      enabled: false
    }
  },
  props: ['isEnabled'],
  methods: {
    handleChange (e) {
      this.$emit('set-value', e)
    }
  },
  watch: {
    isEnabled (v) {
      this.enabled = v
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables';
.homework-registration {
  padding: 1em 0;
  margin-bottom: 2em;
  .el-checkbox__label {
    color: $gray-900 !important;
  }
}
</style>
