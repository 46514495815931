<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    @close="close"
    class="email-modal-receipt"
  >
    <div id="modalReceipt">
      <base-section>
        <p>
          <span class="text-muted"
            >{{ $t('backoffice.orders.general_orders.emailModal.from') }}:</span
          >
          <span v-html="emailData.from"></span>
        </p>
      </base-section>
      <base-section>
        <p>
          <span class="text-muted"
            >{{
              $t('backoffice.orders.general_orders.emailModal.subject')
            }}:</span
          >
          <span class="text-bold" v-html="emailData.subject"></span>
        </p>
      </base-section>
      <base-section>
        <div v-html="emailData.content" />
      </base-section>
      <base-section v-if="attachments && attachments.length">
        <p>
          <span class="text-muted"
            >{{
              $t('backoffice.orders.general_orders.emailModal.attachments')
            }}:</span
          >
        </p>
        <ul>
          <li v-for="(attachment, index) in attachments" :key="index">
            <a
              :href="
                `data:${attachment.mimeType};base64,${attachment.fileContentBase64}`
              "
              :download="attachment.fileName"
              >{{ attachment.fileName }}</a
            >
          </li>
        </ul>
      </base-section>
    </div>
    <base-button
      @click.stop="handlePrint"
      prepend-icon="ri-printer-line"
    ></base-button>
  </el-dialog>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'ShowEmailReceipt',
  props: {
    emailData: {
      type: [Object, String, null],
      default: () => {}
    }
  },
  data () {
    return {
      dialogFormVisible: false
    }
  },
  computed: {
    attachments () {
      return get(this.emailData, 'attachments', [])
    }
  },
  methods: {
    show () {
      this.dialogFormVisible = true
    },
    close () {
      this.dialogFormVisible = false
    },
    handlePrint () {
      const mywindow = window.open('', 'PRINT')

      mywindow.document.write(
        '<html><head><title>' + document.title + '</title>'
      )
      mywindow.document.write('</head><body >')
      mywindow.document.write(document.getElementById('modalReceipt').innerHTML)
      mywindow.document.write('</body></html>')

      mywindow.document.close() // necessary for IE >= 10
      mywindow.focus() // necessary for IE >= 10*/

      mywindow.print()
      mywindow.close()

      return true
    }
  }
}
</script>

<style lang="scss">
.email-modal-receipt {
  .el-dialog {
    width: 85%;
    max-width: 850px;

    .el-dialog__body {
      display: flex;
      flex-direction: column;

      .el-button {
        margin-left: auto;
      }
    }
  }

  .base-section {
    background: #fff;
    padding: 26px 0 10px;
    word-break: break-word;
    display: flex;
    flex-wrap: nowrap;
  }

  .base-section__content {
    min-height: unset !important;
  }
}
</style>
