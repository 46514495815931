<template>
  <base-section class="table-wrapper app-user-expenses">
    <el-alert type="info" :closable="false" class="registrations-alert" show-icon>
      <span v-html="$t('user_management.view_app_users_page.trip_registrations.alert_text')" />
    </el-alert>
    <el-form ref="form" :inline="true" label-position="top" :model="form" @submit.prevent.native="fetchData" class="filter-form">
      <div class="date-control">
        <el-button circle icon="ri-arrow-left-s-line" @click="previousDate" size="small" />
        <base-datepicker :key="dateKey" v-model="form.date" format="MMMM yyyy" :clearable="false" value-format="yyyy-MM" type="month" />
        <el-button circle icon="ri-arrow-right-s-line" size="small" @click="nextDate" />
      </div>
    </el-form>
    <el-table
      id="registrationsTable"
      ref="table"
      :data.sync="tableData"
      :row-class-name="'table-row'"
      :empty-text="$t('general.empty_table')"
      style="width: 100%"
    >
      <el-table-column
        prop="date"
        min-width="110"
        :label="$t('user_group_management.trip_registrations_page.table.date')"
        class-name="text-bold"
      >
        <template slot-scope="{ row }">
          <span>{{ $dateFormat(row.date) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        min-width="145"
        :label="$t('user_group_management.trip_registrations_page.table.type')"
        class-name="text-bold"
      >
        <template slot-scope="{ row }">
          <span>{{ transformText(row.travelReason) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="mobilityMode"
        min-width="120"
        :label="$t('user_group_management.trip_registrations_page.table.mobility_mode')"
        class-name="text-bold"
      >
        <template slot-scope="{ row }">
          <span>{{ transformText(row.mobilityMode) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="distance"
        min-width="100"
        :label="$t('user_group_management.trip_registrations_page.table.distance')"
        class-name="text-bold"
      >
        <template slot-scope="{ row }">
          <span>{{ transformNumber(row.distance) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="compensation" min-width="110" :label="$t('user_group_management.trip_registrations_page.table.compensation')">
        <template slot-scope="{ row }">
          <span>{{ $currency(row.compensation) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="homeWorkPeriod"
        min-width="120"
        :label="$t('user_group_management.trip_registrations_page.table.homework_day')"
      >
        <template slot-scope="{ row }">
          <span>{{ transformNumber(row.homeWorkPeriod) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="carType" min-width="120" :label="$t('user_group_management.trip_registrations_page.table.car_type')">
        <template slot-scope="{ row }">
          <span>{{ transformText(row.carType) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="origin" min-width="175" :label="$t('user_group_management.trip_registrations_page.table.origin')">
        <template slot-scope="{ row }">
          <span>{{ row.origin }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="destination" min-width="175" :label="$t('user_group_management.trip_registrations_page.table.destination')">
        <template slot-scope="{ row }">
          <span>{{ row.destination }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reference" min-width="130" :label="$t('user_group_management.trip_registrations_page.table.reference')">
        <template slot-scope="{ row }">
          <span>{{ row.reference }}</span>
        </template>
      </el-table-column>
    </el-table>
  </base-section>
</template>

<script>
import ViewAppUser from '../mixins/ViewAppUser'
import TableLoader from '@/mixins/TableLoader'
import { debounce } from 'lodash'
import { DATE_TIME_ISO } from '../app/helpers'

const DATE_FORMAT = 'yyyy-MM-dd'

export default {
  name: 'AppUserTripRegistrations',
  mixins: [ViewAppUser, TableLoader],
  props: ['userOspId'],
  data () {
    return {
      form: {
        date: this.$date.local().toFormat(DATE_TIME_ISO)
      },
      dateKey: '',
      tableData: []
    }
  },
  computed: {
    filter () {
      return {
        userOspId: this.userOspId,
        startDate: this.$date
          .fromISO(this.form.date)
          .startOf('month')
          .toFormat(DATE_FORMAT),
        endDate: this.$date
          .fromISO(this.form.date)
          .endOf('month')
          .toFormat(DATE_FORMAT)
      }
    }
  },
  methods: {
    async fetchData (withLoading = true, filter = this.filter) {
      const loading = withLoading && this.loading()
      try {
        await this.$api.travel.getTravelRegistrations(filter, {
          onSuccess: response => {
            this.setData(response)
          }
        })
      } catch (e) {
        console.error(e)
      }
      withLoading && loading.close()
      return true
    },
    loading () {
      return this.TableLoader_show()
    },
    previousDate () {
      this.form.date = this.$date
        .fromISO(this.form.date)
        .minus({ month: 1 })
        .toFormat(DATE_FORMAT)
      this.dateKey = this.$key()
    },
    nextDate () {
      this.form.date = this.$date
        .fromISO(this.form.date)
        .plus({ month: 1 })
        .toFormat(DATE_FORMAT)
      this.dateKey = this.$key()
    },
    setData (data) {
      this.tableData = data
    },
    transformText (data) {
      if (typeof data === 'string') {
        switch (data) {
          case 'CAR':
          case 'BIKE':
          case 'WALKING':
            return this.$t(`user_management.section.trip_registration_${data.toLowerCase()}.title`)
          case 'COMMUTE':
            return this.$t('user_group_management.trip_registrations_page.table.data.commute')
          case 'HOME_WORK':
            return this.$t('user_group_management.trip_registrations_page.table.data.homework')
          case 'BUSINESS_TRIP':
            return this.$t('user_group_management.trip_registrations_page.table.data.business_trip')
          case 'COMPANY':
            return this.$t('user_group_management.trip_registrations_page.table.data.company_car')
          case 'PRIVATE':
            return this.$t('user_group_management.trip_registrations_page.table.data.private_car')
          default:
            return data
        }
      } else {
        return data
      }
    },
    transformNumber (data) {
      if (typeof data === 'number') {
        return data.toFixed(0)
      } else {
        return data
      }
    }
  },
  watch: {
    'form.date': debounce(function () {
      this.fetchData()
    }, 300)
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style lang="scss">
@import '~@/styles/variables.scss';
.registrations-alert {
  margin-bottom: 2em;
}
.table-description {
  color: $gray-600;
  font-weight: bold;
  margin: 0 auto 16px 150px;
}
</style>
