var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{key:_vm.key,staticClass:"table-wrapper business-trip-registration",attrs:{"title":_vm.$t(`user_management.section.trip_registration_bike.title`)}},[_c('div',{staticClass:"table-container-wrapper"},[_c('div',{staticStyle:{"width":"350px"}}),_c('el-table',{ref:"table",staticClass:"el-table-plug",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"empty-text":_vm.$t('user_management.section.trip_registration.empty_text', {
          firstname: _vm.employeeFirstName
        }),"row-class-name":"table-row"},on:{"update:data":function($event){_vm.tableData=$event}}},[_c('el-table-column',{attrs:{"class-name":"text-bold","prop":"allowance","label":_vm.$t(
            'user_management.section.trip_registration.table_header.allowance_per_km'
          ),"min-width":"150"}}),_c('el-table-column',{attrs:{"fixed":"right","class-name":"actions-cell","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('base-button',{attrs:{"size":"small","prepend-icon":"ri-delete-bin-2-line","label":_vm.$t('general.label.remove')},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(scope.row)}}})]}}])})],1)],1),(_vm.tableData.length === 0)?_c('base-button',{staticStyle:{"float":"right"},attrs:{"prepend-icon":"ri-add-line","label":_vm.$t('general.label.add')},on:{"click":function($event){$event.stopPropagation();return _vm.dialogOpen.apply(null, arguments)}}}):_vm._e(),_c('el-dialog',{attrs:{"title":_vm.$t('user_management.section.trip_registration_business.dialog.title'),"visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.dialogClose}},[_c('el-form',{key:_vm.dialogKey,attrs:{"model":_vm.form,"label-position":"top"}},[[_c('el-form-item',{attrs:{"label":_vm.$t(
              'user_management.section.trip_registration_business.dialog.explanation.bike'
            )}})],_c('base-currency-input',{attrs:{"disabled":true,"label":_vm.$t(
            'user_management.section.trip_registration.table_header.compensation_per_km'
          ),"maxWidth":142,"precision":4},model:{value:(_vm.form.allowedPerKm),callback:function ($$v) {_vm.$set(_vm.form, "allowedPerKm", $$v)},expression:"form.allowedPerKm"}})],2),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('base-button',{attrs:{"label":_vm.$t('general.cancel')},on:{"click":function($event){_vm.dialogFormVisible = false}}}),_c('base-button',{attrs:{"loading":_vm.updating,"type":"primary","label":_vm.edit
            ? _vm.$t('general.button_label.save_changes')
            : _vm.$t(
                'user_management.section.trip_registration.button_label.enable_business_trip'
              )},on:{"click":_vm.handleUpdate}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }