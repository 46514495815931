<template>
  <base-section
    :title="$t(`user_management.section.trip_registration_car.title`)"
    :key="key"
    class="table-wrapper business-trip-registration"
  >
    <div class="table-container-wrapper">
      <el-table
        ref="table"
        :data.sync="tableData"
        :empty-text="
          $t('user_management.section.trip_registration.empty_text', {
            firstname: employeeFirstName
          })
        "
        row-class-name="table-row"
        style="width: 100%"
        class="el-table-plug"
      >
        <el-table-column
          class-name="text-bold"
          prop="carType"
          :label="
            $t(
              'user_management.section.trip_registration.table_header.car_type'
            )
          "
          width="350"
        >
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="allowance"
          :label="
            $t(
              'user_management.section.trip_registration.table_header.allowance_per_km'
            )
          "
          min-width="150"
        >
        </el-table-column>
        <el-table-column fixed="right" class-name="actions-cell" width="150">
          <template slot-scope="scope">
            <base-button
              @click.stop="handleDelete(scope.row)"
              size="small"
              prepend-icon="ri-delete-bin-2-line"
              :label="$t('general.label.remove')"
            >
            </base-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <base-button
      v-if="tableData.length === 0"
      @click.stop="dialogOpen"
      prepend-icon="ri-add-line"
      style="float: right;"
      :label="$t('general.label.add')"
    />
    <el-dialog
      :title="
        $t('user_management.section.trip_registration_business.dialog.title')
      "
      :visible.sync="dialogFormVisible"
      @close="dialogClose"
    >
      <el-form :model="form" label-position="top" :key="dialogKey">
        <el-form-item
          :label="
            $t(
              'user_management.section.trip_registration_business.dialog.explanation.car'
            )
          "
          :key="carTypeKey"
          id="car-business-trips"
        >
          <template v-if="isShowCarTypeSelect">
            <base-radio label="COMPANY" :value="carType" @input="change">
              {{
                $t(
                  'user_management.section.trip_registration.business_trip.company'
                )
              }}
            </base-radio>
            <base-radio label="PRIVATE" :value="carType" @input="change">
              {{
                $t(
                  'user_management.section.trip_registration.business_trip.private'
                )
              }}
            </base-radio>
            <p class="error" v-if="isError">
              {{ $t('general.label.required') }}
            </p>
          </template>
        </el-form-item>
        <base-currency-input
          v-model="form.allowedPerKm"
          :disabled="true"
          :label="
            $t(
              'user_management.section.trip_registration.table_header.compensation_per_km'
            )
          "
          :maxWidth="142"
          :precision="4"
        />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <base-button
          :label="$t('general.cancel')"
          @click="dialogFormVisible = false"
        />
        <base-button
          :loading="updating"
          type="primary"
          :label="
            edit
              ? $t('general.button_label.save_changes')
              : $t(
                  'user_management.section.trip_registration.button_label.enable_business_trip'
                )
          "
          @click="handleUpdate"
        />
      </span>
    </el-dialog>
  </base-section>
</template>
<script>
import { mapGetters } from 'vuex'
import { confirmDefaultOptions } from '@/app/helpers'
import ViewAppUser from '@/mixins/ViewAppUser'
import TableLoader from '@/mixins/TableLoader'
import Api from '@/app/services/ApiService/Api'

const carTypes = { COMPANY: 'COMPANY', PRIVATE: 'PRIVATE' }

export default {
  name: 'TripRegistrationsBusinessCarSection',
  mixins: [ViewAppUser, TableLoader],
  props: {
    userOspId: {
      type: String
    }
  },
  data () {
    return {
      key: '',
      config: {},
      edit: false,
      dialogKey: '',
      dialogFormVisible: false,
      updating: false,
      tableData: [],
      form: {
        allowedPerKm: null,
        isAdding: false
      },
      compensationPerKm: {},
      carType: null,
      carTypeKey: null,
      isError: false
    }
  },
  computed: {
    ...mapGetters({
      langDefault: 'i18n/langDefault',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    travelCompensation () {
      return this.corporateAccount.travelCompensation
    },
    isShowCarTypeSelect () {
      return (
        this.travelCompensation.businessTripEnabledForCompanyCar &&
        this.travelCompensation.businessTripEnabledForPrivateCar
      )
    }
  },
  watch: {
    langDefault () {
      this.setData(this.config)
    },
    compensationPerKm () {
      this.setData(this.config)
    },
    carType (v) {
      this.form.allowedPerKm = this.compensationPerKm[v]
      this.dialogKey = this.$key()
      this.isError = false
    }
  },
  methods: {
    async fetchData (withLoading = true) {
      if (!this.$refs.table) {
        return false
      }
      withLoading && this.TableLoader_show()
      try {
        await this.$api.travel.getBusinessTripConfig(this.userOspId, {
          onSuccess: res => this.setData(res)
        })
      } catch (e) {
        console.error(e)
      }
      withLoading && this.TableLoader_hide()
      return true
    },
    async fetchCompensationsData (carType) {
      const filter = {
        corporateAccountOspId: this.corporateAccount.ospId,
        mobilityMode: 'CAR',
        carType
      }
      const options = {
        onSuccess: res => {
          if (res.length && res.length > 0) {
            this.compensationPerKm[carType] =
              res[res.length - 1].compensationPerKm
          }
        }
      }
      try {
        await this.$api.compensations.getBusinessTripCompensation(
          filter,
          options
        )
      } catch (e) {
        console.error(e)
      }
    },
    async handleUpdate () {
      if (
        !this.travelCompensation.businessTripEnabledForCompanyCar &&
        !this.travelCompensation.businessTripEnabledForPrivateCar
      ) {
        Api.pushMessage(
          this.$t(
            'user_management.view_app_users_page.trip_registrations.error_message'
          ),
          'error'
        )
        this.dialogFormVisible = false
        return
      }
      if (this.isShowCarTypeSelect && !this.carType && this.form.isAdding) {
        this.isError = true
        return
      }
      const data = {
        ...this.config
      }
      if (this.carType === carTypes.COMPANY || !this.form.isAdding) {
        data.businessTripEnabledForCompanyCar = this.form.isAdding
      }
      if (this.carType === carTypes.PRIVATE || !this.form.isAdding) {
        data.businessTripEnabledForPrivateCar = this.form.isAdding
      }
      this.updating = true
      try {
        await this.$api.travel.updateBusinessTripConfig(data, {
          onSuccess: res => {
            this.dialogFormVisible = false
            this.setData(res)
          },
          onFinally: () => {
            this.updating = false
          }
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleDelete (data) {
      this.$confirm(
        this.$t(
          'user_management.section.trip_registration.confirm_delete_business.explanation',
          { username: this.username }
        ),
        this.$t(
          'user_management.section.trip_registration.confirm_delete_business.title'
        ),
        confirmDefaultOptions
      ).then(() => {
        this.form.isAdding = false
        return this.handleUpdate()
      })
    },
    dialogOpen () {
      this.form.isAdding = true
      this.dialogFormVisible = true
    },
    dialogClose () {
      this.form.isAdding = false
      // this.form.allowedPerKm = null
      this.edit = false
      this.dialogKey = this.$key()
    },
    setData (config) {
      this.config = config
      const {
        businessTripEnabledForCompanyCar,
        businessTripEnabledForPrivateCar
      } = config
      const tableData = []
      if (
        (this.travelCompensation.businessTripEnabledForCompanyCar ||
          this.travelCompensation.businessTripEnabledForPrivateCar) &&
        !this.isShowCarTypeSelect
      ) {
        if (this.travelCompensation.businessTripEnabledForCompanyCar) {
          this.carType = carTypes.COMPANY
        }
        if (this.travelCompensation.businessTripEnabledForPrivateCar) {
          this.carType = carTypes.PRIVATE
        }
      }
      if (businessTripEnabledForCompanyCar) {
        tableData.push({
          carType: this.$t(
            'user_management.section.trip_registration.business_trip.company'
          ),
          allowedPerKm: this.compensationPerKm.COMPANY,
          allowance: '€ ' + this.compensationPerKm.COMPANY
        })
      }
      if (businessTripEnabledForPrivateCar) {
        tableData.push({
          carType: this.$t(
            'user_management.section.trip_registration.business_trip.private'
          ),
          allowedPerKm: this.compensationPerKm.PRIVATE,
          allowance: '€ ' + this.compensationPerKm.PRIVATE
        })
      }
      this.tableData = tableData
      this.key = this.$key()
    },
    change (v) {
      this.carType = v
      this.carTypeKey = this.$key()
    }
  },
  async mounted () {
    const promises = []
    promises.push(this.fetchCompensationsData(carTypes.COMPANY))
    promises.push(this.fetchCompensationsData(carTypes.PRIVATE))
    await Promise.all(promises)
    this.fetchData()
  }
}
</script>

<style lang="scss">
#car-business-trips {
  .el-form-item__content {
    margin-bottom: 0;
  }
}
</style>
