<template>
  <el-form @submit.prevent.native="null" v-loading="loading">
    <template v-for="(data, tokenType) in tableData">
      <base-section
        :key="tokenType"
        :title="
          $t(
            `user_management.view_app_users_page.identification_tokens.token_type.${tokenType}`
          )
        "
      >
        <el-table
          ref="table"
          :data="data"
          :empty-text="$t('general.empty_table')"
          row-class-name="table-row"
        >
          <template v-for="(column, index) in getColumnsForToken(tokenType)">
            <el-table-column
              v-if="column.extraField"
              :key="tokenType + index"
              :prop="column.prop"
              :label="column.label"
              :min-width="column.minWidth"
              show-overflow-tooltip
              sortable
            >
              <template slot-scope="{ row }">
                {{ row.getFieldToken(column.prop) }}
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :key="tokenType + index"
              :prop="column.prop"
              :label="column.label"
              :min-width="column.minWidth"
              :width="column.width"
              show-overflow-tooltip
              sortable
            ></el-table-column>
          </template>
          <el-table-column fixed="right" :width="60">
            <template slot-scope="{ row }">
              <base-button
                :key="row.tokenUUID"
                class="delete-button"
                type="small"
                @click.stop="deleteToken(row)"
                prepend-icon="ri-delete-bin-2-line"
              />
            </template>
          </el-table-column>
        </el-table>
        <base-button
          class="add-circle-button"
          @click="() => showModal(tokenType)"
          prepend-icon="ri-add-line"
          type="primary"
          :round="false"
          circle
        />
      </base-section>
    </template>
    <add-identification-token-modal
      ref="addModal"
      :userOspId="userOspId"
      @added="fetchData"
    ></add-identification-token-modal>
  </el-form>
</template>

<script>
import store from '@/store'
import ViewAppUser from '../mixins/ViewAppUser'
import IdentificationTokenRepository from '@/app/repositories/IdentificationTokenRepository'
import { mapGetters } from 'vuex'
import AddIdentificationTokenModal from '@/components/ViewAppUser/AddIdentificationTokenModal'

const identificationTokenRepository = new IdentificationTokenRepository()

export default {
  name: 'AppUserIdentificationFieldsView',
  components: { AddIdentificationTokenModal },
  mixins: [ViewAppUser],
  props: ['userOspId'],
  data () {
    return {
      key: '',
      loading: false,
      identificationTokens: []
    }
  },
  computed: {
    ...mapGetters({
      tokenTypes: 'identificationTokens/tokenTypes',
      fieldTypes: 'identificationTokens/fieldTypes'
    }),
    tableData () {
      const tableData = {}
      const identificationTokens = this.identificationTokens.filter(
        v => !v.deleted
      )
      this.tokenTypes.map(tokenType => {
        tableData[tokenType] = identificationTokens.filter(
          v => v.tokenType === tokenType
        )
      })
      return tableData
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      return identificationTokenRepository.get(this.userOspId, {
        onSuccess: response => {
          this.identificationTokens = response.filter(v => v.isValid())
        },
        onFinally: () => {
          this.loading = false
        }
      })
    },
    deleteToken (identificationToken) {
      this.loading = true
      identificationToken.delete({
        successNotification: this.$t(
          'user_management.view_app_users_page.identification_tokens.success_notifications'
        ),
        onSuccess: this.fetchData,
        onError: () => {
          this.loading = false
        }
      })
    },
    // async handleSaveChanges () {
    //   const promises = []
    //   this.saving = true
    //   this.identificationTokens.forEach(token => {
    //     console.log(token)
    //     if (!token.tokenUUID && !token.deleted) {
    //       promises.push(token.add())
    //     } else if (token.tokenUUID && token.deleted) {
    //       promises.push(token.delete())
    //     }
    //   })
    //   try {
    //     await Promise.all(promises)
    //     Api.pushMessage(this.$t('user_management.view_app_users_page.identification_tokens.success_notifications'))
    //     await this.fetchData()
    //   } catch (e) {
    //     console.error(e)
    //   }
    //   this.saving = false
    //   this.dirty = false
    //   this.saveDisabled = true
    // },
    getColumnsForToken (token) {
      return [
        {
          prop: 'name',
          minWidth: '120',
          label: this.$t('general.label.name')
        },
        ...this.getExtraColumnsForTokenType(token),
        {
          prop: 'validFromDate',
          width: '120',
          label: this.$t('general.table_header.valid_from')
        }
      ]
    },
    getExtraColumnsForTokenType (token) {
      if (token === 'ENECO_CHARGING') {
        token = 'CARD_NUMBER'
      }
      return this.fieldTypes
        .filter(v => v.indexOf(token) === 0)
        .map(fieldType => ({
          prop: fieldType,
          label: this.$t(
            `user_management.view_app_users_page.identification_tokens.field_type.${fieldType}`
          ),
          extraField: true,
          minWidth: 120
        }))
    },
    async showModal (tokenType) {
      this.$refs.addModal.show(tokenType)
    },
    addToken (identificationToken) {
      this.identificationTokens.push(identificationToken)
    }
  },
  async mounted () {
    await this.fetchData()
  },
  async beforeRouteEnter (to, from, next) {
    const promises = []
    if (!store.getters['identificationTokens/tokenTypes'].length) {
      promises.push(store.dispatch('identificationTokens/fetchTokenTypes'))
    }
    if (!store.getters['identificationTokens/fieldTypes'].length) {
      promises.push(store.dispatch('identificationTokens/fetchFieldTypes'))
    }
    await Promise.all(promises)
    next()
  }
}
</script>
