var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-user-budgets"},[_c('el-alert',{attrs:{"type":"info","closable":false,"show-icon":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('user_management.view_app_users_page.budgets.alert'))}})]),_c('base-section',{staticClass:"table-wrapper",attrs:{"title":_vm.$t('user_management.section.budgets.title'),"is-flex":false}},[_c('el-form',[_c('base-checkbox',{attrs:{"label":_vm.$t('user_management.view_app_users_page.budgets.show_deactivated')},model:{value:(_vm.showDeactivated),callback:function ($$v) {_vm.showDeactivated=$$v},expression:"showDeactivated"}})],1),_c('el-table',{ref:"appUserBudgetTable",staticClass:"hide-expand-column",staticStyle:{"width":"100%"},attrs:{"id":"appUserBudgetTable","data":_vm.tableData,"empty-text":_vm.$t('user_management.view_app_users_page.budgets.table_empty', {
          username: _vm.username
        }),"row-class-name":"table-row","default-expand-all":""},on:{"update:data":function($event){_vm.tableData=$event},"row-click":_vm.rowClick}},[_c('el-table-column',{attrs:{"type":"expand","width":"1px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.activationStatus === 'ACTIVE')?[_c('base-button',{key:"addExpense",staticClass:"add-item",attrs:{"prepend-icon":"ri-add-line","type":"primary","to":{ name: 'AppUserExpensesAdd' },"label":_vm.$t(
                  'user_management.view_app_users_page.expenses.button_add_expense'
                )}}),(scope.row.limited)?_c('base-button',{key:"addAmount",staticClass:"add-item",attrs:{"type":"primary","label":_vm.$t(
                  'user_management.view_app_users_page.budgets.button_change_budget_amount'
                )},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  name: 'AppUserBudgetAmountAdd',
                  params: { budgetOspId: scope.row.ospId },
                  query: { frequencyMethod: scope.row.frequencyMethod }
                })}}}):_vm._e()]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"class-name":"text-bold","prop":"type","label":_vm.$t('general.label.type')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('div',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.getBudgetName(scope.row)))]),_c('div',{staticClass:"budget-type-info",domProps:{"innerHTML":_vm._s(scope.row.budgetType.info)}}),_c('div',{staticClass:"plug",domProps:{"innerHTML":_vm._s(scope.row.budgetType.info)}}),_c('div',{staticClass:"text-extra-small text-muted"},[_vm._v(" "+_vm._s(_vm.getBudgetTransferStrategy(scope.row))+" ")])])]}}])}),_c('el-table-column',{attrs:{"class-name":"text-bold","prop":"frequency","label":_vm.$t(
            'user_management.view_app_users_page.budgets.table_header_top-up'
          )},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.active && scope.row.frequency)?[_vm._v(" "+_vm._s(_vm.$t( 'user_management.add_app_users_page.set_budget.select_frequency.option_' + scope.row.frequency.toLowerCase() ))+" ")]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"currentAmount","label":_vm.$t(
            'user_management.view_app_users_page.budgets.table_header_top-up_amount'
          )},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.active)?[(scope.row.frequencyMethod === 'MANUAL')?[_vm._v(" "+_vm._s(_vm.$t( 'user_management.view_app_users_page.budgets.not_applicable' ))+" ")]:(scope.row.frequency)?[_vm._v(" "+_vm._s(_vm.$currency(scope.row.frequencyAmount))+" ")]:_vm._e()]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"currentAmount","label":_vm.$t(
            'user_management.view_app_users_page.budgets.table_header_remaining'
          )},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.activationStatus === 'ACTIVE' && scope.row.limited
            )?[_vm._v(" "+_vm._s(_vm.$currency(scope.row.currentAmount))+" "),(
                scope.row.currentAmountUntil &&
                scope.row.frequencyMethod !== 'MANUAL'
              )?_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t( 'user_management.view_app_users_page.budgets.amount_until_date', { date: scope.row.currentAmountUntil } ))+" ")]):_vm._e()]:(!scope.row.limited)?[_c('span',[_vm._v(_vm._s(_vm.$t('user_management.view_app_users_page.budgets.unlimited')))])]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"fixed":"right","class-name":"actions-cell","width":"165"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.activationStatus === 'ACTIVE')?[_c('base-button',{key:"edit",attrs:{"size":"small","prepend-icon":"ri-pencil-line","label":_vm.$t('general.button_label.edit')},on:{"click":function($event){$event.stopPropagation();return _vm.handleEdit(scope.row)}}}),_c('base-button',{key:"delete",attrs:{"size":"small","prepend-icon":"ri-delete-bin-2-line"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(scope.row)}}})]:[_c('base-button',{key:"reactivate",attrs:{"size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.handleReactivate(scope.row)}}},[_vm._v(" "+_vm._s(_vm.$t( 'user_management.view_app_users_page.budgets.table_action_remaining' ))+" ")])]]}}])})],1),(_vm.types.length)?_c('base-button',{attrs:{"prepend-icon":"ri-add-line","to":{ name: 'AppUserBudgetAdd' },"label":_vm.$t('user_management.view_app_users_page.budgets.button_add_budget')}}):_vm._e()],1),(_vm.dirtyData.length)?_c('app-user-budget-expenses',{attrs:{"userOspId":_vm.userOspId,"budgets":_vm.dirtyData}}):_vm._e(),(_vm.dirtyData.length)?_c('app-user-budget-timeline',{attrs:{"userOspId":_vm.userOspId}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }