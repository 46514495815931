<template>
  <el-dialog
    :title="$t('user_management.view_app_users_page.identification_tokens.add_dialog.title')"
    :visible="visible"
    :destroy-on-close="true"
    @close="visible = false"
  >
    <el-form
      :key="key"
      label-position='top'
    >
      <base-input
        required
        :label="$t('general.label.label')"
        v-model="form.label.defaultValue"
        :validations="$v.form.label.defaultValue"
      />
      <base-translations
        :label="$t('general.label.translations')"
        v-model="form.label.labelTranslations"
      />
      <br>
      <template v-for="(identificationField, idx) in form.identificationFields">
        <base-input
          required
          :key="idx"
          :label="$t(`user_management.view_app_users_page.identification_tokens.field_type.${identificationField.type}`)"
          v-model="identificationField.data"
          :validations="$v.form.identificationFields.$each.$iter[idx].data"
        />
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <base-button
          :label="$t('general.cancel')"
          @click="visible = false"
        ></base-button>
        <base-button
          type="primary"
          v-loading="saving"
          :label="$t('general.confirm')"
          @click="() => addToken(form)"
        ></base-button>
      </span>
  </el-dialog>
</template>

<script>

import { required, requiredIf } from 'vuelidate/lib/validators'
import IdentificationToken from '@/app/models/IdentificationToken'
import { validateIdentificationToken } from '@/app/helpers/validate'

const defaultForm = () => ({
  tokenType: null,
  label: {
    defaultValue: '',
    labelTranslations: []
  },
  identificationFields: [],
  userOspId: { ospId: null }
})

export default {
  name: 'AddIdentificationTokenModal',
  props: ['userOspId'],
  data () {
    return {
      visible: false,
      form: defaultForm(),
      key: null,
      saving: false
    }
  },
  validations () {
    return {
      form: {
        label: {
          defaultValue: {
            required
          }
        },
        identificationFields: {
          $each: {
            data: {
              required: requiredIf(function () {
                return this.form.identificationFields.every(({ data }) => !data)
              }),
              valid: validateIdentificationToken(function (nestedModel) {
                return nestedModel.data && nestedModel.type
              })
            }
          }
        }
      }
    }
  },
  methods: {
    async show (tokenType) {
      this.$v.form.$reset()
      this.form = IdentificationToken.create(this.userOspId, tokenType)
      this.key = this.$key()
      this.visible = true
    },
    addToken (identificationToken) {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }
      this.saving = true
      // this.$emit('add', identificationToken)
      identificationToken.add({
        onSuccess: () => {
          this.$emit('added')
          this.visible = false
        },
        onFinally: () => {
          this.saving = false
        },
        successNotification: this.$t('user_management.view_app_users_page.identification_tokens.success_notifications')
      })
    }
  }
}
</script>

<style scoped>

</style>
