<template>
  <div class="user-trip-view-container">
    <el-alert type="info" :closable="false" show-icon>
      <span
        v-html="
          $t('user_management.view_app_users_page.view_trips.alert_text', {
            username,
            firstname: employeeFirstName
          })
        "
      />
    </el-alert>

    <div class="registration-section">
      <h2>
        {{ $t('user_group_management.trip_registration_page.commuting_trip_registration.title') }}
      </h2>
      <trip-registrations-section
        v-if="isCarCommuteCompensationTypePerKilometer"
        :userOspId="userOspId"
        :mode="'CAR'"
      />
      <trip-registrations-per-day-section
        v-if="isCarCommuteCompensationTypePerDay"
        :userOspId="userOspId"
        :mode="'CAR'"
      />

      <trip-registrations-section :userOspId="userOspId" :mode="'BIKE'">
        <el-form v-if="bikeAllowanceCheckboxVisible" id="commute-bike-form" @submit.prevent.native="() => {}">
          <base-checkbox
            :key="bikeRegistrationCheckboxKey"
            :label="$t('user_management.section.trip_registration.bike.pay_allowance_with_mobility_budget.label')"
            v-model="shouldPayBikeAllowanceOnLegalMobilityBudget"
            :disabled="bikeAllowanceCheckboxDisabled"
          ></base-checkbox>
          <el-alert type="info" :closable="false" show-icon>
            <span
              v-if="bikeAllowanceCheckboxDisabled"
              v-html="
                $t(
                  'user_management.section.trip_registration.bike.pay_allowance_with_mobility_budget.disabled.explanation'
                )
              "
            ></span>
            <span
              v-else
              v-html="
                $t('user_management.section.trip_registration.bike.pay_allowance_with_mobility_budget.explanation')
              "
            ></span>
          </el-alert>
        </el-form>
      </trip-registrations-section>

      <trip-registrations-section :userOspId="userOspId" :mode="'WALKING'" />
    </div>

    <div class="registration-section">
      <h2>{{ $t('user_management.section.hw_registration.title') }}</h2>
      <el-form ref="form" :model="form" @submit.prevent.native="() => {}">
        <homework-registration-section :isEnabled="form.homeWorkEnabled" @set-value="setValue" />
      </el-form>
    </div>
    <div class="registration-section">
      <h2>
        {{ $t('user_group_management.trip_registration_page.business_trip_registration.title') }}
      </h2>
      <trip-registrations-business-car-section :userOspId="userOspId" />
      <trip-registrations-business-bike-section :userOspId="userOspId" />
    </div>
  </div>
</template>

<script>
import ViewAppUser from '../mixins/ViewAppUser'
import { mapGetters } from 'vuex'
import TripRegistrationsSection from '../components/sections/TripRegistrationsSection'
import TripRegistrationsPerDaySection from '../components/sections/TripRegistrationsPerDaySection'
import TripRegistrationsBusinessBikeSection from '../components/sections/TripRegistrationsBusinessBikeSection'
import TripRegistrationsBusinessCarSection from '../components/sections/TripRegistrationsBusinessCarSection'
import HomeworkRegistrationSection from '../components/sections/HomeworkRegistrationSection'

export default {
  name: 'AppUserTripView',
  components: {
    TripRegistrationsSection,
    TripRegistrationsPerDaySection,
    TripRegistrationsBusinessBikeSection,
    TripRegistrationsBusinessCarSection,
    HomeworkRegistrationSection
  },
  mixins: [ViewAppUser],
  props: ['userOspId'],
  data () {
    return {
      form: {
        homeWorkEnabled: null
      },
      saveDisabled: true,
      saving: false,
      bikeAllowanceCheckboxVisible: false,
      bikeAllowanceCheckboxDisabled: false,
      shouldPayBikeAllowanceOnLegalMobilityBudget: false,
      defaultShouldPayBikeAllowanceOnLegalMobilityBudget: false,
      bikeRegistrationCheckboxKey: null
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    isCarCommuteCompensationTypePerDay () {
      return this.corporateAccount.travelCompensation.carCommuteCompensationType === 'PER_DAY'
    },
    isCarCommuteCompensationTypePerKilometer () {
      return this.corporateAccount.travelCompensation.carCommuteCompensationType === 'PER_KILOMETER'
    }
  },
  methods: {
    async fetchHomeworkConfiguration () {
      try {
        await this.$api.compensations.getHomeworkConfiguration(this.userOspId, {
          onSuccess: res => {
            this.setData(res)
            this.saveDisabled = false
          }
        })
      } catch (e) {
        console.error(e)
      }
    },
    async fetchUserCommuteStatus () {
      try {
        await this.$api.compensations.getCommuteUserStatus(this.userOspId, {
          onSuccess: res => {
            this.shouldPayBikeAllowanceOnLegalMobilityBudget = res.shouldPayBikeAllowanceOnLegalMobilityBudget
            this.defaultShouldPayBikeAllowanceOnLegalMobilityBudget = res.shouldPayBikeAllowanceOnLegalMobilityBudget
            this.bikeAllowanceCheckboxVisible = res.bikeAllowanceCheckboxVisible
            this.bikeAllowanceCheckboxDisabled = res.bikeAllowanceCheckboxDisabled
          }
        })
      } catch (e) {
        console.error(e)
      }
    },
    setData (data) {
      this.form = data
    },
    setValue (value) {
      this.form.homeWorkEnabled = value
    },
    saveCommuteUserStatus () {
      const data = {
        userOspId: this.userOspId,
        shouldPayBikeAllowanceOnLegalMobilityBudget: this.shouldPayBikeAllowanceOnLegalMobilityBudget
      }
      const options = {
        onSuccess: () => {
          this.fetchUserCommuteStatus()
        },
        onError: () => {
          this.shouldPayBikeAllowanceOnLegalMobilityBudget = this.defaultShouldPayBikeAllowanceOnLegalMobilityBudget
        },
        onFinally: () => {
          this.bikeRegistrationCheckboxKey = this.$key()
        }
      }
      return this.$api.compensations.updateCommuteUserStatus(data, options)
    },
    async handleSaveChanges () {
      this.saving = true
      const data = {
        homeWorkEnabled: this.form.homeWorkEnabled,
        userOspId: this.userOspId
      }
      const options = {
        successNotification: this.$t('user_management.view_app_users_page.details.success_notification_save'),
        onSuccess: () => {
          this.fetchHomeworkConfiguration()
        },
        onFinally: () => {
          this.saving = false
        }
      }
      try {
        await this.saveCommuteUserStatus()
        await this.$api.compensations.updateHomeworkConfiguration(data, options)
      } catch (e) {
        console.error(e)
        this.saving = false
      }
    }
  },
  mounted () {
    this.fetchHomeworkConfiguration()
    this.fetchUserCommuteStatus()
  }
}
</script>

<style lang="scss">
@import '@/styles/variables';
.user-trip-view-container {
  .registration-section {
    border-top: 1px solid $gray-200;
    margin-top: 1em;
  }
  .base-section + .base-section--with-border {
    border-top: none !important;
  }
  .base-section__content {
    border-top: 1px solid $gray-200;
  }
  .base-section__title {
    margin-top: 0.5em;
  }
  .el-form-item__content {
    margin-bottom: 2em;
  }
}
.business-trip-registration {
  .table-container-wrapper {
    display: flex;
  }
  .el-table {
    border-top: 1px solid #dce0e9;
  }
  .base-section__content {
    border-top: none !important;
  }
}
#commute-bike-form {
  padding: 10px 0 0 10px;

  .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item__content {
    margin-bottom: 0;
  }
}
</style>
