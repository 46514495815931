<template>
  <base-section :title="$t('user_management.section.expense_details.title')" :with-border="false">
    <el-form
      ref="form"
      label-position="top"
      :model="form"
      @submit.prevent.native="null"
      :key="key"
      autocomplete="off"
    >
      <el-row :gutter="24">
        <el-col :span="12">
          <base-currency-input
            :label="$t('user_management.view_app_users_page.add_expense.input_amount.label')"
            :required="true"
            ref="priceVatIncl"
            prop="priceVatIncl"
            v-model="form.priceVatIncl"
            :key="priceVatInclKey"
            :validations="$v.form.priceVatIncl"
            name="startpriceVatIncl"
            :disabled="!edit"
          />
        </el-col>
        <el-col :span="12">
          <base-select
            :label="$t('user_management.view_app_users_page.add_expense.input_vat_perc.label')"
            :required="true"
            ref="vatPercentage"
            prop="vatPercentage"
            :items="vatPercentages"
            v-model="form.vatPercentage"
            :validations="$v.form.vatPercentage"
            name="vatPercentage"
            :disabled="!edit"
          />
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <base-input
            :label="$t('user_management.view_app_users_page.add_expense.input_description.label')"
            name="description"
            :required="true"
            :placeholder="
              $t('user_management.view_app_users_page.add_expense.input_description.placeholder', {
                number: 140
              })
            "
            :validations="$v.form.description"
            v-model="form.description"
            maxlength="140"
            :show-word-limit="true"
            :disabled="!edit"
          />
        </el-col>
        <el-col :span="12">
          <base-select
            :label="$t('user_management.view_app_users_page.add_expense.input_budget.label')"
            :required="true"
            ref="budget"
            prop="budgets"
            :items="budgetsList"
            :disabled="true"
            :validations="$v.form.budgetOspId"
            v-model="form.budgetOspId"
            name="budgets"
          />
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <base-datepicker
            :label="$t('user_management.view_app_users_page.add_expense.input_end_date.label')"
            :required="true"
            v-model="form.endDate"
            :validations="$v.form.endDate"
            :clearable="false"
            :picker-options="endDatePickerOptions"
            :disabled="!edit"
            :type="datePickerType"
            :placeholder="dateDisplaying.placeholder"
            :format="dateDisplaying.format"
          />
        </el-col>
        <el-col :span="12">
          <base-select
            :label="$t('user_management.view_app_users_page.add_expense.input_frequency.label')"
            :required="true"
            ref="frequency"
            prop="frequency"
            :disabled="true"
            :items="frequencies"
            v-model="form.recurrence"
            :validations="$v.form.recurrence"
            name="frequency"
          />
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <base-datepicker
            v-model="form.startDate"
            :label="$t('user_management.view_app_users_page.recurring_expenses.starts_on')"
            :disabled="true"
            :clearable="false"
            :type="datePickerType"
            :placeholder="dateDisplaying.placeholder"
            :format="dateDisplaying.format"
            :info="dateDisplaying.info"
          />
        </el-col>
        <el-col :span="12">
          <base-select
            :label="$t('general.label.mobility_mode')"
            :required="true"
            ref="mobilityMode"
            prop="mobilityMode"
            :disabled="true"
            :items="mobilityModes"
            v-model="form.mobilityMode"
            name="mobilityMode"
          >
            <template v-slot:option="{ items }">
              <el-option
                v-for="item in items"
                :key="item.mobilityMode"
                :label="$trans(item.label)"
                :value="item.mobilityMode"
                class="dashboard-user-selection"
              ></el-option>
            </template>
          </base-select>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <base-currency-input
            v-if="form.priceFirstOccurrenceVatIncl"
            :label="$t('general.label.expenses.firstAmount')"
            name="priceFirstOccurrenceVatIncl"
            v-model="form.priceFirstOccurrenceVatIncl"
            :disabled="true"
          />
        </el-col>
        <el-col :span="12">
          <base-currency-input
            v-if="form.priceLastOccurrenceVatIncl"
            :label="$t('general.label.expenses.lastAmount')"
            name="priceLastOccurrenceVatIncl"
            v-model="form.priceLastOccurrenceVatIncl"
            :disabled="true"
          />
        </el-col>
      </el-row>
    </el-form>
  </base-section>
</template>
<script>
import { mapGetters } from 'vuex'
import { greaterThanZero } from '@/app/helpers/validate'
import { required } from 'vuelidate/lib/validators'
import { DATE_PICKER_TYPES, dateDisplayInDatePicker } from '@/app/helpers'

export default {
  name: 'ExpenseDetailsEditSection',
  props: {
    budgets: {
      type: Array,
      default: () => []
    },
    edit: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, null, undefined]
    },
    validations: {
      type: Object
    }
  },
  data () {
    return {
      priceVatInclKey: 'startpriceVatInclKey',
      vatPercentages: [
        {
          label: '0%',
          value: 0
        },
        {
          label: '6%',
          value: 6
        },
        {
          label: '12%',
          value: 12
        },
        {
          label: '21%',
          value: 21
        }
      ],
      form: {
        priceVatIncl: 0,
        vatPercentage: null,
        description: '',
        recurrence: null,
        startDate: null,
        endDate: null,
        budgetOspId: null,
        mobilityMode: null,
        priceFirstOccurrenceVatIncl: null,
        priceLastOccurrenceVatIncl: null
      },
      key: ''
    }
  },
  computed: {
    ...mapGetters({
      // budgetTypes: 'budget/budgetTypes',
      corporateAccount: 'corporateAccount/selectedCorporateAccount',
      recurringTypes: 'recurringExternalPurchase/types',
      mobilityModes: 'general/mobilityModes'
    }),
    budgetTypes () {
      return this.corporateAccount.budgetTypes
    },
    frequencies () {
      return this.recurringTypes.map(v => {
        return {
          value: v.recurrenceType,
          label: this.$trans(v.label)
        }
      })
    },
    budgetsList () {
      return this.budgets.map(v => {
        const type = this.budgetTypes.find(type => type.budgetType === v.type)
        return {
          value: v.ospId,
          label: this.$trans(type.budgetTypeName || '')
        }
      })
    },
    datePickerOptions () {
      return {}
    },
    endDatePickerOptions () {
      return {
        disabledDate: value => {
          return (
            value <
            this.$date
              .fromISO(this.form.startDate)
              .startOf('day')
              .plus({ [`${this.datePickerType}s`]: 1 })
              .toJSDate()
          )
        }
      }
    },
    datePickerType () {
      return this.form.recurrence === 'YEARLY' ? DATE_PICKER_TYPES.YEAR : DATE_PICKER_TYPES.MONTH
    },
    dateDisplaying () {
      return dateDisplayInDatePicker(this.datePickerType)
    }
  },
  watch: {
    form: {
      handler: function (newValue, oldValue) {
        this.$emit('input', newValue)
      },
      deep: true
    }
  },
  validations () {
    return {
      form: {
        priceVatIncl: {
          greaterThanZero
        },
        vatPercentage: {
          required
        },
        description: {
          required
        },
        recurrence: {
          required
        },
        endDate: {
          required
        },
        budgetOspId: {
          required
        }
      }
    }
  },
  created () {
    if (this.value) {
      this.form = this.value
    }
    this.key = this.$key()
  }
}
</script>

<style scoped></style>
