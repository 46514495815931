<template>
  <base-section :title="budget.name" :with-border="false" class="budget-item-section">
    <el-form ref="form" label-position="top" :model="form" @submit.prevent.native="null" :key="key" autocomplete="off">
      <base-checkbox
        v-if="value.allowUnlimited"
        :label="$t('user_management.add_app_users_page.set_budget.checkbox_unlimited.label')"
        :explanation="$t('user_management.add_app_users_page.set_budget.checkbox_unlimited.explanation')"
        v-model="form.unlimited"
      />
      <template v-if="!form.unlimited">
        <template v-if="!edit">
          <base-checkbox
            :disabled="form.frequency === null"
            :label="$t('user_management.add_app_users_page.set_budget.checkbox_recurring.label')"
            v-model="form.equalToRecurring"
          />
          <base-currency-input
            :label="$t('user_management.add_app_users_page.set_budget.input_startamount.label')"
            :disabled="form.equalToRecurring"
            :max-width="232"
            :info="$t('user_management.add_app_users_page.set_budget.input_startamount.explanation')"
            ref="startamount"
            prop="startamount"
            v-model="form.startAmount"
            :key="startamountKey"
            :validations="validations && validations.startAmount"
            name="startamount"
          />
        </template>
        <base-currency-input
          :key="frequencyAmountKey"
          :disabled="form.frequency === null"
          :label="$t('user_management.add_app_users_page.set_budget.input_frequency_amount.label')"
          :info="$t('user_management.add_app_users_page.set_budget.input_frequency_amount.explanation')"
          :extra-info="previousAmount"
          ref="frequencyAmount"
          :max-width="232"
          prop="frequencyAmount"
          v-model="form.frequencyAmount"
          :validations="validations && validations.frequencyAmount"
          name="frequencyAmount"
        />
        <template v-if="frequencies.length > 1">
          <div class="recurrence-settings">
            <base-select
              :label="selectFrequencyLabel"
              ref="frequency"
              :max-width="232"
              prop="frequency"
              :items="frequencies"
              v-model="form.frequency"
              name="frequency"
            />
            <base-select
              v-if="showEndOfYearRecurrenceStrategyInput"
              ref="endOfYearRecurrenceStrategy"
              :max-width="232"
              prop="endOfYearRecurrenceStrategy"
              :items="recurrenceStrategies"
              v-model="form.endOfYearRecurrenceStrategy"
              name="endOfYearRecurrenceStrategy"
            />
            <base-select
              v-if="isEditPage && form.frequencyMethod === 'AUTOMATIC'"
              ref="frequencyAmountUpdateStrategy"
              :max-width="232"
              prop="frequencyAmountUpdateStrategy"
              :items="frequencyAmountUpdateStrategies"
              v-model="form.frequencyAmountUpdateStrategy"
              name="frequencyAmountUpdateStrategy"
            />
          </div>

          <div v-if="showRadioGroup" class="radio-group">
            <base-radio
              v-for="(value, index) in strategiesForFrequency"
              :key="index"
              :label="value"
              v-model="transferStrategy"
              :explanation="strategiesForFrequency.length === index + 1 ? transferStrategyExplanation : ''"
            >
              {{
                $t(
                  `user_management.add_app_users_page.set_budget.checkbox_transfer_strategy_${form.frequency}_${value}.label`
                )
              }}
            </base-radio>
          </div>
        </template>
        <base-checkbox
          :label="vatIncludedTitle"
          v-model="form.vatIncluded"
          :disabled="vatIncludedDisable"
          :explanation="vatIncludedExplanation"
        />
      </template>
    </el-form>
  </base-section>
</template>

<script>
import { first, get } from 'lodash'
import { currency } from '@/app/helpers'

export default {
  name: 'BudgetItemSection',
  props: {
    budget: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, null, undefined]
    },
    validations: {
      type: Object
    }
  },
  data () {
    return {
      startamountKey: 'startamountKey',
      frequencyAmountKey: 'frequencyAmountKey',
      form: this.value,
      key: ''
    }
  },
  computed: {
    transferStrategy: {
      get () {
        return this.form.transferStrategy
      },
      set (v) {
        this.form.transferStrategy = v
        this.key = this.$key()
      }
    },
    strategiesForFrequency () {
      return this.budget.allowedStrategiesForFrequency[this.form.frequency]
    },
    transferStrategyDisable () {
      return this.budget.allowedStrategies.filter(v => v.value === 'ADD' || v.value === 'RESET').length < 2
    },
    vatIncludedDisable () {
      return this.budget.allowedVatStrategies.length < 2
    },
    frequencies () {
      const frequencies = []
      this.budget.allowedFrequencies.forEach(v => {
        frequencies.push({
          label: this.$trans(v.label),
          value: v.value
        })
      })
      if (this.budget.allowedFrequencyMethods.map(v => v.value).includes('MANUAL')) {
        frequencies.push({
          label: this.$t('user_management.add_app_users_page.set_budget.select_frequency.option_null'),
          value: null
        })
      }
      return frequencies
    },
    vatIncludedTitle () {
      if (this.form.isMobilityBudget) {
        return this.$t('user_management.add_app_users_page.set_budget.checkbox_tva_included.mobility_label')
      }
      return this.$t('user_management.add_app_users_page.set_budget.checkbox_tva_included.label')
    },
    vatIncludedExplanation () {
      if (this.form.isMobilityBudget) {
        return this.$t('user_management.add_app_users_page.set_budget.checkbox_tva_included.mobility_explanation')
      }
      return this.$t('user_management.add_app_users_page.set_budget.checkbox_tva_included.explanation')
    },
    transferStrategyExplanation () {
      if (this.form.isMobilityBudget) {
        return this.$t('user_management.add_app_users_page.set_budget.checkbox_transfer_strategy.mobility_explanation')
      }
      return ''
    },
    selectFrequencyLabel () {
      return this.$t('user_management.add_app_users_page.set_budget.select_frequency.label_' + this.transferStrategy)
    },
    availableRecurrenceStgrategies () {
      return get(this.form, ['budgetType', 'allowedEndOfYearRecurrenceStrategies'], []) || []
    },
    recurrenceStrategies () {
      return this.availableRecurrenceStgrategies.map(v => ({
        label: this.$t(
          `user_management.add_app_users_page.set_budget.select_recurrence_strategy.${v.value.toLowerCase()}`
        ),
        value: v.value
      }))
    },
    isEditPage () {
      return this.$route.name === 'AppUserBudgetEdit'
    },
    frequencyAmountUpdateStrategies () {
      const frequency = this.form.frequency?.toLowerCase() || 'month'
      const availableStrategies = ['NEXT_PERIOD', 'CURRENT_PERIOD']
      return availableStrategies.map(v => ({
        type: this.form.frequency,
        label: this.$t(
          `user_management.add_app_users_page.set_budget.select_update_strategy.${v.toLowerCase()}.${frequency}`
        ),
        value: v
      }))
    },
    previousAmount () {
      if (this.isEditPage && !this.form.frequencyAmount && this.form.previousFrequencyAmount) {
        return this.$t('user_management.add_app_users_page.set_budget.input_frequency_amount.previous_amount', {
          amount: currency(this.form.previousFrequencyAmount)
        })
      }
      return ''
    },
    showEndOfYearRecurrenceStrategyInput () {
      return (
        this.form.endOfYearRecurrenceStrategy !== undefined &&
        this.form.frequencyMethod !== 'MANUAL' &&
        this.recurrenceStrategies.length
      )
    },
    showRadioGroup () {
      if (this.form.budgetType?.budgetType === 'CAFETERIA_BUDGET') {
        return !(this.form.frequency === 'YEAR' && this.form.endOfYearRecurrenceStrategy === 'STOP')
      }
      return true
    }
  },
  watch: {
    'form.equalToRecurring' (v) {
      if (v) {
        this.form.startAmount = this.form.frequencyAmount
        this.key = this.$key()
      }
    },
    'form.frequencyAmount' (v) {
      if (this.form.equalToRecurring) {
        this.form.startAmount = v
        this.startamountKey = this.$key()
      }
    },
    'form.frequency' (v) {
      if (v === null) {
        this.form.frequencyMethod = 'MANUAL'
        this.form.frequencyAmount = 0
        this.form.equalToRecurring = false
        this.transferStrategy = null
      } else {
        this.form.frequencyMethod = 'AUTOMATIC'
        this.transferStrategy =
          this.transferStrategy && this.strategiesForFrequency.includes(this.transferStrategy)
            ? this.transferStrategy
            : first(this.strategiesForFrequency)
      }
      this.key = this.$key()
    },
    'form.frequencyMethod' (v) {
      if (this.form.budgetType?.budgetType === 'CAFETERIA_BUDGET') {
        if (v === 'MANUAL') {
          this.form.endOfYearRecurrenceStrategy = null
        } else {
          this.form.endOfYearRecurrenceStrategy = get(this.availableRecurrenceStgrategies, ['0', 'value'], null)
        }
      }
    },
    form: {
      handler: function (newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    },
    showRadioGroup (v) {
      if (!v) {
        this.form.transferStrategy = 'RESET'
      } else {
        this.form.transferStrategy = first(this.strategiesForFrequency) || null
      }
    }
  },
  created () {
    if (this.value) {
      this.form = this.value
      if (!this.form.budgetType?.allowedEndOfYearRecurrenceStrategies?.length) {
        this.form.endOfYearRecurrenceStrategy = null
      }
    } else {
      this.form.frequency = this.budget.defaultAllowedFrequency
      this.form.transferStrategy = this.budget.budget.getDefaultAllowedStrategy(this.form.frequency)
      if (!this.budget.allowedVatStrategiesFlat.includes('VAT_INCLUDED')) {
        this.form.vatIncluded = false
      }
    }
    this.form.frequencyMethod = this.form.frequency !== null ? 'AUTOMATIC' : 'MANUAL'
    this.key = this.$key()
  }
}
</script>

<style lang="scss" scoped>
.recurrence-settings {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}
</style>
