<template>
  <base-section :title="$t('user_management.section.expense_details.title')" :with-border="false">
    <el-form
      ref="form"
      label-position="top"
      :model="form"
      @submit.prevent.native="null"
      :key="key"
      autocomplete="off"
    >
      <el-row :gutter="24">
        <el-col :span="12">
          <base-currency-input
            :label="$t('user_management.view_app_users_page.add_expense.input_amount.label')"
            :required="true"
            ref="priceVatIncl"
            prop="priceVatIncl"
            v-model="form.priceVatIncl"
            :key="priceVatInclKey"
            :validations="$v.form.priceVatIncl"
            name="startpriceVatIncl"
          />
        </el-col>
        <el-col :span="12">
          <base-select
            :label="$t('user_management.view_app_users_page.add_expense.input_vat_perc.label')"
            :required="true"
            ref="vatPercentage"
            prop="vatPercentage"
            :items="vatPercentages"
            v-model="form.vatPercentage"
            :validations="$v.form.vatPercentage"
            name="vatPercentage"
          />
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <base-input
            :label="$t('user_management.view_app_users_page.add_expense.input_description.label')"
            name="description"
            :required="true"
            :placeholder="
              $t('user_management.view_app_users_page.add_expense.input_description.placeholder', {
                number: 140
              })
            "
            :validations="$v.form.description"
            v-model="form.description"
            maxlength="140"
            :show-word-limit="true"
          />
        </el-col>
        <el-col :span="12">
          <base-select
            :label="$t('user_management.view_app_users_page.add_expense.input_budget.label')"
            :required="true"
            ref="budget"
            prop="budgets"
            :items="budgetsList"
            :validations="$v.form.budgetOspId"
            v-model="form.budgetOspId"
            name="budgets"
          />
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <base-datepicker
            v-model="form.eventTimestamp"
            :label="$t('user_management.view_app_users_page.recurring_expenses.starts_on')"
            :required="true"
            :validations="$v.form.eventTimestamp"
            :clearable="false"
            :picker-options="expenseDatePickerOptions"
            :type="datePickerType"
            :placeholder="dateDisplaying.placeholder"
            :format="dateDisplaying.format"
            :info="dateDisplaying.info"
          />
        </el-col>
        <el-col :span="12" v-if="form.expensesType && form.expensesType === 'recurring'">
          <base-select
            :label="$t('user_management.view_app_users_page.add_expense.input_frequency.label')"
            :required="true"
            ref="frequency"
            prop="frequency"
            :items="frequencies"
            v-model="form.frequency"
            :validations="$v.form.frequency"
            name="frequency"
          />
        </el-col>
      </el-row>
      <el-row :gutter="24" v-if="form.expensesType && form.expensesType === 'recurring'">
        <el-col :span="12">
          <base-datepicker
            v-model="form.endDate"
            :label="$t('user_management.view_app_users_page.add_expense.input_end_date.label')"
            :validations="$v.form.endDate"
            :clearable="false"
            :picker-options="endDatePickerOptions"
            :type="datePickerType"
            :placeholder="dateDisplaying.placeholder"
            :format="dateDisplaying.format"
          />
        </el-col>
      </el-row>
    </el-form>
  </base-section>
</template>

<script>
import { mapGetters } from 'vuex'
import { greaterThanZero } from '@/app/helpers/validate'
import { required } from 'vuelidate/lib/validators'
import { DATE_PICKER_TYPES, dateDisplayInDatePicker } from '@/app/helpers'

export default {
  name: 'ExpenseDetailsSection',
  props: {
    budgets: {
      type: Array,
      default: () => []
    },
    edit: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, null, undefined]
    },
    validations: {
      type: Object
    }
  },
  data () {
    return {
      priceVatInclKey: 'startpriceVatInclKey',
      vatPercentages: [
        {
          label: '0%',
          value: 0
        },
        {
          label: '6%',
          value: 6
        },
        {
          label: '12%',
          value: 12
        },
        {
          label: '21%',
          value: 21
        }
      ],
      form: {
        priceVatIncl: 0,
        vatPercentage: null,
        description: '',
        frequency: null,
        eventTimestamp: null,
        endDate: null,
        budgetOspId: null
      },
      key: ''
    }
  },
  computed: {
    ...mapGetters({
      // budgetTypes: 'budget/budgetTypes',
      corporateAccount: 'corporateAccount/selectedCorporateAccount',
      recurringTypes: 'recurringExternalPurchase/types'
    }),
    budgetTypes () {
      return this.corporateAccount.budgetTypes
    },
    frequencies () {
      return this.recurringTypes.map(v => {
        return {
          value: v.recurrenceType,
          label: this.$trans(v.label)
        }
      })
    },
    budgetsList () {
      return this.budgets.map(v => {
        const type = this.budgetTypes.find(type => type.budgetType === v.type)
        return {
          value: v.ospId,
          label: this.$trans(type.budgetTypeName || '')
        }
      })
    },
    endDatePickerOptions () {
      return {
        disabledDate: value => {
          return (
            value <
            this.$date
              .fromISO(this.form.eventTimestamp)
              .startOf('day')
              .plus({ [`${this.datePickerType}s`]: 1 })
              .toJSDate()
          )
        }
      }
    },
    expenseDatePickerOptions () {
      if (this.isRecurring) {
        return {
          disabledDate: value => {
            return (
              value >
              this.$date
                .fromISO(this.form.endDate)
                .startOf('day')
                .minus({ [`${this.datePickerType}s`]: 1 })
                .toJSDate()
            )
          }
        }
      } else {
        return {
          disabledDate: value => {
            return value > this.$date.local().endOf('day').toJSDate()
          }
        }
      }
    },
    isRecurring () {
      return this.form?.expensesType === 'recurring'
    },
    datePickerType () {
      if (this.isRecurring) {
        return this.form.frequency === 'YEARLY' ? DATE_PICKER_TYPES.YEAR : DATE_PICKER_TYPES.MONTH
      }
      return DATE_PICKER_TYPES.DEFAULT
    },
    dateDisplaying () {
      return dateDisplayInDatePicker(this.datePickerType)
    }
  },
  methods: {
    resetDates () {
      this.form.eventTimestamp = null
      this.form.endDate = null
      this.key = this.$key()
    }
  },
  watch: {
    form: {
      handler: function (newValue, oldValue) {
        this.$emit('input', newValue)
      },
      deep: true
    },
    'form.expensesType' (v) {
      this.form.frequency = v === 'recurring' ? this.frequencies[0]?.value : null
      this.resetDates()
    },
    'form.frequency' () {
      this.resetDates()
    }
  },
  validations () {
    if (this.form.expensesType && this.form.expensesType === 'recurring') {
      return {
        form: {
          priceVatIncl: {
            greaterThanZero
          },
          vatPercentage: {
            required
          },
          description: {
            required
          },
          frequency: {
            required
          },
          eventTimestamp: {
            required
          },
          budgetOspId: {
            required
          }
        }
      }
    } else {
      return {
        form: {
          priceVatIncl: {
            greaterThanZero
          },
          vatPercentage: {
            required
          },
          description: {
            required
          },
          eventTimestamp: {
            required
          },
          budgetOspId: {
            required
          }
        }
      }
    }
  },
  created () {
    if (this.value) {
      this.form = this.value
    }
    this.key = this.$key()
  }
}
</script>

<style scoped></style>
