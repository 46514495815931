<template>
  <base-section class="user-expenses" :title="$t('dashboard.users_expenses.title')">
    <el-form ref="form" :model="form" autocomplete="off" id="timeline" @submit.prevent.native="null">
      <el-row :key="key" type="flex" justify="space-between" align="center" class="user-expenses--header">
        <base-select
          v-model="form.budget"
          ref="budget"
          prop="budget"
          :items="budgetSelectItems"
          name="budget"
        ></base-select>
        <base-datepicker
          v-model="daterange"
          type="monthrange"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          valueFormat="yyyyMM"
          format="MMMM yyyy"
          :maxWidth="300"
          :clearable="false"
          :picker-options="dateOptions"
          style="margin-right: 0"
        >
        </base-datepicker>
      </el-row>
      <div class="flex-container">
        <base-section class="expenses-chart-section">
          <v-chart
            :key="chartKey"
            class="expenses-chart"
            :class="'expenses-chart--' + this.chartDimensions.class"
            :autoresize="true"
            :options="charOptions"
          />
        </base-section>
        <div class="transactions-section">
          <el-table
            ref="table"
            :data="transactions.tableData"
            :default-sort="transactions.sort"
            :empty-text="$t('general.empty_table')"
            row-class-name="table-row"
            style="width: 100%"
            @sort-change="sortChange"
          >
            <el-table-column
              prop="transportMode"
              :label="$t('user_management.view_app_users_page.budget.transactions.table.transport_mode')"
              min-width="80"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="description"
              :label="$t('general.input_label.description')"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="dateTime"
              :label="$t('user_management.view_app_users_page.budget.transactions.table.created_on')"
              min-width="100"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              prop="currencyAmount"
              class-name="text-right"
              :label="$t('general.input_label.amount')"
              min-width="85"
              sortable="custom"
            ></el-table-column>
          </el-table>
          <base-pagination
            v-if="transactions.pages > 1"
            v-model="transactions.page"
            :pages="transactions.pages"
            style="height: auto"
          ></base-pagination>
        </div>
      </div>
    </el-form>
  </base-section>
</template>

<script>
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/title'
import { sumBy, has, values, groupBy } from 'lodash'
import CancelRequest from '@/mixins/CancelRequest'
import TableLoader from '@/mixins/TableLoader'
import { DATE_TIME_ISO } from '@/app/helpers'
import UserBudgetTransactions from '@/app/models/UserBudgetTransactions'

const defaultSort = () => ({ order: 'descending', prop: 'bookedFor' })

export default {
  name: 'AppUserBudgetExpenses',
  props: ['userOspId', 'budgets'],
  mixins: [CancelRequest, TableLoader],
  data () {
    return {
      chartKey: '',
      form: {
        budget: ''
      },
      transactions: {
        sort: defaultSort(),
        size: 10,
        page: 0,
        pages: 1,
        tableData: []
      },
      key: '',
      dirtyCharData: [],
      colors: ['#003C88', '#008443', '#641964', '#743410', '#BF41DE', '#DD1D21', '#EB8705'],
      daterange: [this.$date.local().toFormat('yyyyMM'), this.$date.local().toFormat('yyyyMM')]
    }
  },
  computed: {
    chartDimensions () {
      switch (true) {
        case this.charData.length >= 12:
          return {
            class: 'extra-large'
          }
        case this.charData.length >= 7:
          return {
            class: 'large'
          }
        case this.charData.length >= 3:
          return {
            class: 'medium'
          }
        default:
          return {
            class: 'small'
          }
      }
    },
    charOptions () {
      return {
        title: {
          text: this.totalSum,
          itemGap: 4,
          textStyle: {
            fontWeight: 600,
            fontSize: 15,
            color: '#282C36'
          },
          left: 'center',
          top: 'center',
          subtext: 'incl. VAT',
          subtextStyle: {
            fontSize: 12,
            color: '#929EAC'
          }
          //  font-size: 12px;

          // color: #929EAC;
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return `${params.data.valueLabel}<br>${params.name}`
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['56px', '68px'],
            startAngle: 0,
            // radius: this.chartDimensions.radius,
            left: '0',
            data: this.charData,
            label: {
              padding: [13, 0, 0, 0],
              fontSize: 13,
              margin: 40,
              formatter: function (params) {
                return `{value|${params.data.valueLabel}}\n{name|${params.name}}`
              },
              rich: {
                name: {
                  fontSize: 12,
                  color: '#617484'
                },
                value: {
                  fontWeight: 600,
                  fontSize: 13
                }
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    },
    totalSum () {
      const sum = sumBy(this.charData, 'value')
      return this.$currency(sum)
    },
    charData () {
      const data = this.dirtyCharData
        .map(item => {
          return {
            name: this.$trans(item.mobilityModeLabel),
            value: Math.abs(item.amount)
          }
        })
        .filter(v => v.value)
        .map((result, idx) => {
          if (has(this.colors, idx)) {
            result.itemStyle = {
              color: this.colors[idx]
            }
          }
          result.valueLabel = this.$currency(result.value)
          return result
        })
      return data
    },
    dateOptions () {
      return {
        disabledDate: value => {
          return value > this.$date.local().endOf('day').toJSDate()
        }
      }
    },
    activeBudgetsList () {
      return this.budgets.filter(v => v.activationStatus === 'ACTIVE')
    },
    endOfLifeBudgetsList () {
      return this.budgets.filter(v => v.activationStatus === 'END_OF_LIFE')
    },
    budgetSelectItems () {
      return [...this.activeBudgetsList, ...this.endOfLifeBudgetsList].map(v => {
        return {
          value: v.ospId,
          label: v.name
        }
      })
    }
  },
  watch: {
    daterange: {
      deep: true,
      handler () {
        this.transactions.page = 0
        this.key = this.$key()
        this.fetchSumTransactions()
        this.fetchTransactions()
      }
    },
    'form.budget' () {
      this.transactions.page = 0
      this.fetchSumTransactions()
      this.fetchTransactions()
    },
    'transactions.page' () {
      this.fetchTransactions()
    },
    'transactions.sort' () {
      this.fetchTransactions()
    },
    activeBudgetsList () {
      this.setCurrentActiveBudget()
      this.key = this.$key()
    }
  },
  methods: {
    fetchSumTransactions () {
      if (!this.form.budget) return
      const form = {
        monthFrom: this.daterange[0],
        monthTo: this.daterange[1],
        budgetOspId: this.form.budget
      }
      this.$api.reporting.sumTransactions(form, {
        onSuccess: response => {
          this.setData(response)
        }
      })
    },
    fetchTransactions () {
      if (!this.form.budget) return
      const sort = {
        sortOrders: [
          {
            direction: (this.transactions.sort.order || defaultSort().order).toUpperCase(),
            property: this.transactions.sort.prop
          }
        ]
      }
      const form = {
        from: this.$date.fromFormat(this.daterange[0], 'yyyyMM').toFormat(DATE_TIME_ISO),
        to: this.$date.fromFormat(this.daterange[1], 'yyyyMM').endOf('month').toFormat(DATE_TIME_ISO),
        budgetOspId: {
          ospId: this.form.budget
        },
        pagination: {
          page: this.transactions.page,
          size: this.transactions.size,
          sort
        },
        userOspId: this.userOspId
      }
      this.TableLoader_show()
      return this.$api.budget.getTransactionsByFilter(form, {
        cancelToken: this.CancelRequest_token(),
        onSuccess: ({ totalPages, content }) => {
          if (totalPages && this.transactions.page > totalPages - 1) {
            this.transactions.page = totalPages - 1
          } else {
            this.transactions.pages = totalPages
            this.transactions.tableData = content.map(v => new UserBudgetTransactions(v))
          }
        },
        onFinally: () => {
          this.TableLoader_hide()
        }
      })
    },
    setData (response) {
      this.dirtyCharData = values(
        groupBy(
          response.filter(({ mobilityMode }) => mobilityMode !== 'NONE'),
          'mobilityMode'
        )
      ).map(items => {
        return {
          ...items[0],
          amount: sumBy(items, ({ amount }) => amount)
        }
      })
    },
    getLabel (items) {
      return this.$trans(items[0].mobilityModeLabel)
    },
    sortChange (sort) {
      if (sort.prop === 'dateTime') {
        this.transactions.sort.prop = 'bookedFor'
      } else if (sort.prop === 'currencyAmount') {
        this.transactions.sort.prop = 'amount'
      } else {
        this.transactions.sort.prop = sort.prop
      }
      this.transactions.sort.order = sort.order
      this.transactions.page = 0
      this.fetchTransactions()
    },
    setCurrentActiveBudget () {
      if (this.activeBudgetsList.length) {
        this.form.budget = this.activeBudgetsList[0].ospId
      } else {
        this.form.budget = ''
        this.transactions.page = 1
        this.transactions.tableData = []
        this.setData([])
      }
    }
  },
  mounted () {
    this.setCurrentActiveBudget()
    this.$nextTick(() => {
      this.key = this.$key()
      this.fetchSumTransactions()
      this.fetchTransactions()
    })
  }
}
</script>
