<template>
  <base-section
    :title="$t(`user_management.section.trip_registration_bike.title`)"
    :key="key"
    class="table-wrapper business-trip-registration"
  >
    <div class="table-container-wrapper">
      <div style="width: 350px"></div>
      <el-table
        ref="table"
        :data.sync="tableData"
        :empty-text="
          $t('user_management.section.trip_registration.empty_text', {
            firstname: employeeFirstName
          })
        "
        row-class-name="table-row"
        style="width: 100%"
        class="el-table-plug"
      >
        <el-table-column
          class-name="text-bold"
          prop="allowance"
          :label="
            $t(
              'user_management.section.trip_registration.table_header.allowance_per_km'
            )
          "
          min-width="150"
        >
        </el-table-column>
        <el-table-column fixed="right" class-name="actions-cell" width="150">
          <template slot-scope="scope">
            <base-button
              @click.stop="handleDelete(scope.row)"
              size="small"
              prepend-icon="ri-delete-bin-2-line"
              :label="$t('general.label.remove')"
            >
            </base-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <base-button
      v-if="tableData.length === 0"
      @click.stop="dialogOpen"
      prepend-icon="ri-add-line"
      style="float: right;"
      :label="$t('general.label.add')"
    />
    <el-dialog
      :title="
        $t('user_management.section.trip_registration_business.dialog.title')
      "
      :visible.sync="dialogFormVisible"
      @close="dialogClose"
    >
      <el-form :model="form" label-position="top" :key="dialogKey">
        <template>
          <el-form-item
            :label="
              $t(
                'user_management.section.trip_registration_business.dialog.explanation.bike'
              )
            "
          ></el-form-item>
        </template>
        <base-currency-input
          v-model="form.allowedPerKm"
          :disabled="true"
          :label="
            $t(
              'user_management.section.trip_registration.table_header.compensation_per_km'
            )
          "
          :maxWidth="142"
          :precision="4"
        />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <base-button
          :label="$t('general.cancel')"
          @click="dialogFormVisible = false"
        />
        <base-button
          :loading="updating"
          type="primary"
          :label="
            edit
              ? $t('general.button_label.save_changes')
              : $t(
                  'user_management.section.trip_registration.button_label.enable_business_trip'
                )
          "
          @click="handleUpdate"
        />
      </span>
    </el-dialog>
  </base-section>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewAppUser from '../../mixins/ViewAppUser'
import { confirmDefaultOptions } from '../../app/helpers'
import TableLoader from '@/mixins/TableLoader'

const vehicle = 'BIKE'

export default {
  name: 'TripRegistrationsBusinessBikeSection',
  mixins: [ViewAppUser, TableLoader],
  props: {
    userOspId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      key: '',
      config: {},
      edit: false,
      dialogKey: '',
      dialogFormVisible: false,
      updating: false,
      tableData: [],
      form: {
        vehicle: null,
        allowedPerKm: null
      },
      compensationPerKm: 0
    }
  },
  computed: {
    ...mapGetters({
      langDefault: 'i18n/langDefault',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    travelCompensation () {
      return this.corporateAccount.travelCompensation
    }
  },
  watch: {
    'form.typeOfVehicle' (v) {
      this.form.allowedPerKm = this.compensationPerKm
      this.dialogKey = this.$key()
    },
    langDefault () {
      this.setData(this.config)
    },
    compensationPerKm () {
      this.setData(this.config)
    }
  },
  methods: {
    async fetchData (withLoading = true) {
      if (!this.$refs.table) {
        return false
      }
      withLoading && this.TableLoader_show()
      try {
        await this.$api.travel.getBusinessTripConfig(this.userOspId, {
          onSuccess: res => this.setData(res)
        })
      } catch (e) {
        console.error(e)
      }
      withLoading && this.TableLoader_hide()
      return true
    },
    async fetchCompensationsData () {
      const filter = {
        corporateAccountOspId: this.corporateAccount.ospId,
        mobilityMode: vehicle
      }
      const options = {
        onSuccess: res => {
          if (res.length && res.length > 0) {
            this.compensationPerKm = res[res.length - 1].compensationPerKm
          }
        }
      }
      try {
        await this.$api.compensations.getBusinessTripCompensation(
          filter,
          options
        )
      } catch (e) {
        console.error(e)
      }
    },
    async handleUpdate () {
      const data = {
        ...this.config
      }
      data.businessTripEnabledForBike = Boolean(this.form.vehicle)
      this.updating = true
      try {
        await this.$api.travel.updateBusinessTripConfig(data, {
          onSuccess: res => {
            this.dialogFormVisible = false
            this.setData(res)
          },
          onFinally: () => {
            this.updating = false
          }
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleDelete () {
      this.$confirm(
        this.$t(
          'user_management.section.trip_registration.confirm_delete_business.explanation',
          { username: this.username }
        ),
        this.$t(
          'user_management.section.trip_registration.confirm_delete_business.title'
        ),
        confirmDefaultOptions
      ).then(() => {
        this.form.vehicle = null
        return this.handleUpdate()
      })
    },
    dialogOpen () {
      this.form.vehicle = vehicle
      this.form.allowedPerKm = this.compensationPerKm
      this.dialogFormVisible = true
    },
    dialogClose () {
      this.form.allowedPerKm = null
      this.form.vehicle = null
      this.edit = false
      this.dialogKey = this.$key()
    },
    setData (config) {
      this.config = config
      const tableData = []
      if (config.businessTripEnabledForBike) {
        tableData.push({
          allowedPerKm: this.compensationPerKm,
          allowance: '€ ' + this.compensationPerKm
        })
      }
      this.tableData = tableData
      this.key = this.$key()
    }
  },
  mounted () {
    this.fetchData()
    this.fetchCompensationsData()
  }
}
</script>

<style lang="scss"></style>
