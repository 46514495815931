var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-trip-view-container"},[_c('el-alert',{attrs:{"type":"info","closable":false,"show-icon":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t('user_management.view_app_users_page.view_trips.alert_text', {
          username: _vm.username,
          firstname: _vm.employeeFirstName
        })
      )}})]),_c('div',{staticClass:"registration-section"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('user_group_management.trip_registration_page.commuting_trip_registration.title'))+" ")]),(_vm.isCarCommuteCompensationTypePerKilometer)?_c('trip-registrations-section',{attrs:{"userOspId":_vm.userOspId,"mode":'CAR'}}):_vm._e(),(_vm.isCarCommuteCompensationTypePerDay)?_c('trip-registrations-per-day-section',{attrs:{"userOspId":_vm.userOspId,"mode":'CAR'}}):_vm._e(),_c('trip-registrations-section',{attrs:{"userOspId":_vm.userOspId,"mode":'BIKE'}},[(_vm.bikeAllowanceCheckboxVisible)?_c('el-form',{attrs:{"id":"commute-bike-form"},nativeOn:{"submit":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('base-checkbox',{key:_vm.bikeRegistrationCheckboxKey,attrs:{"label":_vm.$t('user_management.section.trip_registration.bike.pay_allowance_with_mobility_budget.label'),"disabled":_vm.bikeAllowanceCheckboxDisabled},model:{value:(_vm.shouldPayBikeAllowanceOnLegalMobilityBudget),callback:function ($$v) {_vm.shouldPayBikeAllowanceOnLegalMobilityBudget=$$v},expression:"shouldPayBikeAllowanceOnLegalMobilityBudget"}}),_c('el-alert',{attrs:{"type":"info","closable":false,"show-icon":""}},[(_vm.bikeAllowanceCheckboxDisabled)?_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t(
                'user_management.section.trip_registration.bike.pay_allowance_with_mobility_budget.disabled.explanation'
              )
            )}}):_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('user_management.section.trip_registration.bike.pay_allowance_with_mobility_budget.explanation')
            )}})])],1):_vm._e()],1),_c('trip-registrations-section',{attrs:{"userOspId":_vm.userOspId,"mode":'WALKING'}})],1),_c('div',{staticClass:"registration-section"},[_c('h2',[_vm._v(_vm._s(_vm.$t('user_management.section.hw_registration.title')))]),_c('el-form',{ref:"form",attrs:{"model":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('homework-registration-section',{attrs:{"isEnabled":_vm.form.homeWorkEnabled},on:{"set-value":_vm.setValue}})],1)],1),_c('div',{staticClass:"registration-section"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('user_group_management.trip_registration_page.business_trip_registration.title'))+" ")]),_c('trip-registrations-business-car-section',{attrs:{"userOspId":_vm.userOspId}}),_c('trip-registrations-business-bike-section',{attrs:{"userOspId":_vm.userOspId}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }