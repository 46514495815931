<template>
  <page-layout
    :title="$t('user_management.view_app_users_page.add_expense.title')"
    :has-back-button="true"
  >
    <template #header-actions>
      <base-button
        :label="$t('user_management.view_app_users_page.add_expense.button_label.add')"
        type="primary"
        :loading="saving"
        @click="handleSaveChanges"
      />
    </template>
    <el-alert
      type="info"
      :closable="false"
      show-icon
    >
      <span v-html="$t('user_management.view_app_users_page.add_expense.alert_text', { username })"/>
    </el-alert>
    <el-form ref="form" :model="form" @submit.prevent.native="null" :key="key" autocomplete="off">
      <expenses-types-section
        v-model="form.expensesType"
      />
      <expense-details-section
        ref="expenseDetails"
        id="expenseDetails"
        :budgets="budgets"
        v-model="form"
      />
    </el-form>
  </page-layout>
</template>

<script>
import store from '@/store'
import PageLayout from '../layouts/PageLayout'
import { mapActions, mapGetters } from 'vuex'
import ExpenseDetailsSection from '../components/sections/ExpenseDetailsSection'
import ExpensesTypesSection from '../components/sections/ExpensesTypesSection'
import { DATE_TIME_ISO } from '../app/helpers'

export default {
  name: 'AppUserExpensesAdd',
  components: {
    PageLayout,
    ExpenseDetailsSection,
    ExpensesTypesSection
  },
  props: ['userOspId'],
  data () {
    return {
      saving: false,
      budgetType: null,
      form: {
        expensesType: 'single',
        priceVatIncl: 0,
        vatPercentage: null,
        description: '',
        frequency: null,
        eventTimestamp: null,
        endDate: null,
        budgetOspId: null,
        currency: 'EUR',
        mobilityMode: 'OTHER_PURCHASES'
      },
      key: '',
      budgetTypeKey: 'budgetTypeKey'
    }
  },
  computed: {
    ...mapGetters({
      employeeName: 'user/employeeName',
      userBudgets: 'budget/userBudgets'
    }),
    username () {
      return this.employeeName(this.userOspId)
    },
    budgets () {
      return this.userBudgets(this.userOspId).filter(b => b.active)
    },
    singleExpenseForm () {
      return {
        budgetOspId: this.form.budgetOspId,
        currency: this.form.currency,
        eventTimestamp: this.$dateFormat(this.form.eventTimestamp, DATE_TIME_ISO),
        mobilityMode: this.form.mobilityMode,
        priceVatIncl: this.form.priceVatIncl,
        userOspId: this.userOspId,
        vatPercentage: this.form.vatPercentage,
        description: this.form.description
      }
    },
    recurringExpenseForm () {
      return {
        budgetOspId: this.form.budgetOspId,
        currency: this.form.currency,
        startDate: this.form.eventTimestamp,
        endDate: this.form.endDate,
        priceVatIncl: this.form.priceVatIncl,
        userOspId: this.userOspId,
        vatPercentage: this.form.vatPercentage,
        description: this.form.description,
        recurrence: this.form.frequency
      }
    }
  },
  methods: {
    ...mapActions({
      createBudget: 'budget/createBudget',
      fetchTypes: 'recurringExternalPurchase/fetchTypes'
    }),
    handleSaveChanges () {
      this.$refs.expenseDetails.$v.form.$touch()
      if (this.$refs.expenseDetails.$v.form.$invalid) {
        this.$scrollTo('#expenseDetails')
        return false
      }
      this.saving = true
      if (this.form.expensesType === 'single') {
        return this.$api.event.createCostEventForExternalPurchase(this.singleExpenseForm, {
          onSuccess: () => {
            this.$router.back()
          },
          successNotification: 'Single expense created'
        })
      } else if (this.form.expensesType === 'recurring') {
        return this.$api.recurringExternalPurchase.create(this.recurringExpenseForm, {
          onSuccess: () => {
            this.$router.back()
          },
          successNotification: 'Recurring expense created'
        })
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    const userOspId = to.params.userOspId
    const promises = []
    if (!store.getters['budget/userBudgets'](userOspId).length) {
      promises.push(store.dispatch('budget/getUserBudgets', { ospId: userOspId }))
    }
    if (!store.getters['recurringExternalPurchase/types'].length) {
      promises.push(store.dispatch('recurringExternalPurchase/fetchTypes'))
    }
    await Promise.all(promises)
    next()
  }
}
</script>

<style lang="scss">
</style>
