<template>
  <div>
    <app-user-recurrent-expenses
      ref="recurrentExpenses"
      :user-osp-id="userOspId"
      @handleEdit="handleEdit"
      @handleChange="handleChange"
    />
    <base-section
      :is-flex="false"
      :withBorder="false"
      class="app-user-expenses table-wrapper"
      id="userExpensesViewAll"
    >
      <h2>{{ $t('general.label.expenses.0') }}</h2>
      <el-form
        ref="form"
        :inline="true"
        label-position="top"
        :model="form"
        @submit.prevent.native="fetchData"
        class="filter-form"
        :key="filterKey"
      >
        <div class="date-control">
          <el-button
            circle
            icon="ri-arrow-left-s-line"
            @click="previousDate"
            size="small"
          />
          <base-datepicker
            :key="dateKey"
            v-model="form.date"
            format="MMMM yyyy"
            :clearable="false"
            value-format="yyyy-MM"
            type="month"
          />
          <el-button
            circle
            icon="ri-arrow-right-s-line"
            size="small"
            @click="nextDate"
          />
        </div>
      </el-form>
      <el-table
        id="expensesTable"
        ref="table"
        :data.sync="tableData"
        :row-class-name="'table-row clickable'"
        @row-click="handleRowClick"
        :empty-text="$t('general.empty_table')"
        style="width: 100%"
      >
        <el-table-column
          class-name="text-bold with-icon"
          prop="description"
          min-width="150"
          :label="$t('general.input_label.description')"
        >
          <template slot-scope="{ row }">
            <span
              v-if="row.recurrenceOspId && row.externalPurchase"
              class="cell-icon"
              ><i class="ri-repeat-line"></i></span
            ><span>{{ row.description }}</span>
          </template>
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="createdOn"
          min-width="90"
          :label="$t('general.input_label.date')"
        >
          <template slot-scope="{ row }">
            {{ $dateFormat(row.createdOn) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="mrpName"
          min-width="120"
          :label="$t('general.input_label.mobility_provider')"
        >
        </el-table-column>
        <el-table-column
          prop="budgetName"
          :label="$t('general.label.budget')"
          min-width="135"
        >
        </el-table-column>
        <el-table-column
          prop="price"
          :label="
            $t(
              'user_management.view_app_users_page.budgets.table_header_total_amount'
            )
          "
          min-width="135"
        >
          <template slot-scope="{ row }">
            {{ $currency(row.price.priceVatIncl) }}
          </template>
        </el-table-column>
        <el-table-column width="80">
          <template slot-scope="scope">
            <base-button
              v-if="scope.row.hasEmailReceipt"
              @click.stop="handleShow(scope.row)"
              size="small"
              prepend-icon="ri-mail-line"
            >
            </base-button>
          </template>
        </el-table-column>
        <el-table-column fixed="right" class-name="actions-cell" width="135">
          <template slot-scope="scope">
            <template
              v-if="!!scope.row.recurrenceOspId && scope.row.externalPurchase"
            >
              <base-button
                @click.stop="handleDelete(scope.row)"
                size="small"
                prepend-icon="ri-delete-bin-2-line"
              >
              </base-button>
              <base-button
                v-if="scope.row.recurrenceEditable"
                @click.stop="handleEdit(scope.row)"
                size="small"
                prepend-icon="ri-pencil-line"
                :label="$t('general.button_label.edit')"
              />
            </template>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        :title="$t('user_management.section.expenses.dialog.title')"
        :close-on-click-modal="false"
        :visible.sync="dialog.visible"
        :key="dialog.key"
        @close="dialogClose"
      >
        <p
          v-if="dialog.form.recurrence.recurrence"
          v-html="
            $t(
              'user_management.section.expenses.dialog.expense_details.' +
                dialog.form.recurrence.recurrence.toLowerCase(),
              {
                amount: this.$currency(
                  (dialog.form.event.price &&
                    dialog.form.event.price.priceVatIncl) ||
                    dialog.form.event.priceVatIncl
                ),
                date: this.$dateFormat(
                  dialog.form.event.createdOn || dialog.form.event.startDate
                )
              }
            )
          "
        ></p>
        <el-form :model="dialog.form" label-position="top" :key="dialog.key">
          <base-radio
            v-model="dialog.form.type"
            :key="dialog.form.type + 'this'"
            prop="type"
            name="type"
            label="0"
            :explanation="
              $t(
                'user_management.section.expenses.dialog.option_this.explanation'
              )
            "
          >
            {{
              $t('user_management.section.expenses.dialog.option_this.label')
            }}
          </base-radio>
          <base-radio
            v-model="dialog.form.type"
            :key="dialog.form.type + 'all'"
            prop="type"
            name="type"
            label="1"
            :explanation="
              $t(
                'user_management.section.expenses.dialog.option_all.explanation'
              )
            "
          >
            {{ $t('user_management.section.expenses.dialog.option_all.label') }}
          </base-radio>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <base-button
            :label="$t('general.cancel')"
            @click="dialog.visible = false"
          />
          <base-button
            :loading="dialog.loading"
            type="danger"
            :label="$t('user_management.section.expenses.dialog.button_submit')"
            @click="deleteEvent"
          />
        </span>
      </el-dialog>
      <show-email-receipt :emailData="getEmailData" ref="emailReceipt" />
    </base-section>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import ViewAppUser from '../mixins/ViewAppUser'
import { DATE_TIME_ISO } from '../app/helpers'
import CancelRequest from '@/mixins/CancelRequest'
import TableLoader from '@/mixins/TableLoader'
import Role from '@/app/services/RolesService/Role'
import ShowEmailReceipt from '@/components/ViewAppUser/ShowEmailReceipt'
import AppUserRecurrentExpenses from '../components/ViewAppUser/AppUserRecurrentExpenses'

const defaultSort = { order: 'descending', prop: 'createdOn' }
const DATE_FORMAT = 'yyyy-LL'

const dialogDefaultConfig = () => {
  return {
    visible: false,
    form: {
      type: '0',
      event: {
        price: {}
      },
      recurrence: {
        recurrence: ''
      }
    },
    key: 'dialog',
    loading: false
  }
}

export default {
  name: 'AppUserExpensesViewAll',
  components: { ShowEmailReceipt, AppUserRecurrentExpenses },
  mixins: [ViewAppUser, CancelRequest, TableLoader],
  props: ['userOspId', 'totalPages', 'pagination'],
  data () {
    const eventTimestampFrom = this.$date
      .local()
      .startOf('month')
      .toFormat(DATE_TIME_ISO)
    const eventTimestampTo = this.$date
      .local()
      .endOf('month')
      .toFormat(DATE_TIME_ISO)
    return {
      dialog: dialogDefaultConfig(),
      form: {
        date: this.$date.local().toFormat(DATE_FORMAT)
      },
      filterKey: '',
      dateKey: '',
      from: eventTimestampFrom,
      to: eventTimestampTo,
      tableData: [],
      budgetType: '',
      corporateAccountOspId: {
        ospId: ''
      },
      emailData: ''
    }
  },
  computed: {
    ...mapGetters({
      selectedAccountCorporate: 'corporateAccount/selectedAccountCorporate',
      userCorporateAccount: 'corporateAccount/userCorporateAccount'
    }),
    filter () {
      return {
        payerOrBuyerUserOspId: this.userOspId,
        eventTimestampFrom: this.$date
          .fromISO(this.form.date)
          .startOf('month')
          .toFormat(DATE_TIME_ISO),
        eventTimestampTo: this.$date
          .fromISO(this.form.date)
          .endOf('month')
          .toFormat(DATE_TIME_ISO),
        pagination: {
          page: this.pagination.page,
          size: this.pagination.size,
          sort: {
            sortOrders: [
              {
                direction: (
                  this.pagination.sort.order || defaultSort.order
                ).toUpperCase(),
                property: this.pagination.sort.prop
              }
            ]
          }
        }
      }
    },
    getEmailData () {
      return this.emailData
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.fetchData()
    }, 200),
    'pagination.sort': debounce(function () {
      this.fetchData()
    }, 200),
    'form.date': debounce(function () {
      if (this.pagination.page !== 0) {
        this.pagination.page = 0
      } else {
        this.fetchData()
      }
    }, 300)
  },
  methods: {
    async fetchData (withLoading = true, filter = this.filter) {
      const loading = withLoading && this.loading()
      try {
        await this.$api.event.getByFilter(filter, {
          cancelToken: this.CancelRequest_token(),
          onSuccess: response => {
            this.setData(response)
          }
        })
      } catch (e) {
        console.error(e)
      }
      withLoading && loading.close()
      return true
    },
    loading () {
      return this.TableLoader_show()
    },
    handleRowClick (event) {
      if (this.$auth.hasRole([Role.OSPADMIN, Role.USER_MANAGER])) {
        this.$router.push({
          name: 'AppUserExpensesViewDetails',
          params: {
            ...this.$route.params,
            eventOspId: event.eventOspId || event.ospId
          },
          query: this.$route.query
        })
      }
    },
    handleEdit (event) {
      this.$router.push({
        name: 'AppUserExpensesEdit',
        params: {
          ...this.$route.params,
          recurrenceOspId: event.recurrenceOspId || event.ospId
        }
      })
    },
    async handleDelete (event) {
      this.dialog.form.event = event
      const loading = this.loading()
      this.$api.recurringExternalPurchase.get(
        { ospId: event.recurrenceOspId || event.ospId },
        {
          onSuccess: response => {
            this.dialog.form.recurrence = response
            this.dialog.visible = true
          },
          onFinally: () => {
            loading.close()
          }
        }
      )
    },
    previousDate () {
      this.form.date = this.$date
        .fromISO(this.form.date)
        .minus({ month: 1 })
        .toFormat(DATE_FORMAT)
      this.dateKey = this.$key()
    },
    nextDate () {
      this.form.date = this.$date
        .fromISO(this.form.date)
        .plus({ month: 1 })
        .toFormat(DATE_FORMAT)
      this.dateKey = this.$key()
    },
    setData ({ content, totalPages }) {
      this.tableData = content
      this.$emit('update:totalPages', totalPages)
    },
    sortChange (sort) {
      this.pagination.sort = sort
    },
    deleteEvent () {
      this.dialog.loading = true
      const options = {
        successNotification: this.$t(
          'user_management.section.expenses.dialog.success_notification'
        ),
        onSuccess: () => {
          this.dialogClose()
          this.fetchData()
        },
        onFinally: () => {
          this.dialog.loading = false
        }
      }

      if (this.dialog.form.type === '1') {
        this.$api.recurringExternalPurchase.delete({
          ospId: this.dialog.form.recurrence.ospId
        })
      }
      this.$api.event.refund(
        {
          eventOspId: {
            ospId: this.dialog.form.event.eventOspId
          }
        },
        options
      )
    },
    async handleShow (data) {
      const { eventOspId } = data
      try {
        await this.$api.event.getEmailReceipt(
          { eventOspId },
          {
            onSuccess: response => {
              this.emailData = response
            }
          }
        )
      } catch (e) {
        console.error(e)
        return
      }
      this.$refs.emailReceipt.show()
    },
    dialogClose () {
      this.dialog = dialogDefaultConfig()
      this.dialog.key = this.$key()
    },
    handleChange () {
      this.fetchData()
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      this.fetchData()
    })
    this.$emit('update:pagination', {
      page: 0,
      size: 15,
      sort: { ...defaultSort }
    })
  }
}
</script>
