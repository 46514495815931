<template>
  <base-section :title="$t(`user_management.section.trip_registration_${mode.toLowerCase()}.title`)" :key="key" class="table-wrapper">
    <el-table ref="table" :data.sync="tableData" :empty-text="emptyText" row-class-name="table-row" style="width: 100%">
      <el-table-column min-width="100"></el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="allowance"
        :label="$t('user_management.section.trip_registration.table_header.allowance_per_day')"
        min-width="105"
      >
        <template slot-scope="{ row }">
          {{ $currency(row.compensationPerDay) }}
        </template>
      </el-table-column>
      <el-table-column prop="validForm" :label="$t('user_management.section.trip_registration.table_header.valid_form')" min-width="90">
        <template slot-scope="{ row }">
          {{ $dateFormat(row.validFrom) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" class-name="actions-cell" width="200">
        <template slot-scope="scope">
          <base-button
            @click.stop="handleEdit(scope.row)"
            size="small"
            prepend-icon="ri-pencil-line"
            :label="$t('general.button_label.edit')"
          />
          <base-button
            @click.stop="handleDelete(scope.row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
            :label="$t('general.label.remove')"
          >
          </base-button>
        </template>
      </el-table-column>
    </el-table>
    <base-button
      v-if="commuteEnabled"
      @click.stop="dialogFormVisible = true"
      prepend-icon="ri-add-line"
      style="float: right;"
      :label="$t('general.label.add')"
    />
    <el-dialog
      :title="$t('user_management.section.trip_registration.dialog.title')"
      :visible.sync="dialogFormVisible"
      @close="dialogClose"
      :key="dialogKey"
    >
      <el-form :model="form" label-position="top" :key="dialogKey">
        <base-datepicker
          v-model="form.validFrom"
          :label="$t('user_management.section.trip_registration.input_label.valid_from')"
          :picker-options="datePickerOptions"
          :maxWidth="142"
          :validations="$v.form.validFrom"
        />
        <base-currency-input
          :key="compensationKey"
          v-model="form.compensationPerDay"
          :label="$t('user_management.section.trip_registration.input_label.compensation_per_day')"
          :maxWidth="142"
          :precision="4"
        />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <base-button :label="$t('general.cancel')" @click="dialogFormVisible = false" />
        <base-button
          :loading="updating"
          type="primary"
          :label="
            edit
              ? $t('general.button_label.save_changes')
              : $t('user_management.section.trip_registration.commute.per_day.button_label.add_allowance')
          "
          @click="handleUpdate"
        />
      </span>
    </el-dialog>
  </base-section>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewAppUser from '../../mixins/ViewAppUser'
import { confirmDefaultOptions } from '../../app/helpers'
import { required } from 'vuelidate/lib/validators'
import TableLoader from '@/mixins/TableLoader'
import { DateTime } from 'luxon'

export default {
  name: 'TripRegistrationsPerDaySection',
  mixins: [ViewAppUser, TableLoader],
  props: {
    userOspId: {
      type: String,
      default: ''
    },
    mode: {
      type: String
    },
    data: {
      type: Array
    }
  },
  data () {
    return {
      edit: false,
      compensationKey: '',
      travelData: {},
      key: '',
      dialogKey: '',
      tableData: [],
      dialogFormVisible: false,
      compensation: null,
      updating: false,
      form: {
        validFrom: null,
        compensationPerDay: 0
      }
    }
  },
  validations: {
    form: {
      compensationPerDay: {
        required
      },
      validFrom: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    travelCompensation () {
      return this.corporateAccount.travelCompensation
    },
    datePickerOptions () {
      return {
        disabledDate: value => {
          if (this.travelData.maximumProcessedDate) {
            return (
              value <
              DateTime.fromSQL(this.travelData.maximumProcessedDate)
                .startOf('day')
                .plus({ days: 1 })
                .toJSDate()
            )
          }
          return false
        }
      }
    },
    commuteEnabled () {
      if (this.mode === 'BIKE') {
        return this.travelCompensation.commuteEnabledForBike
      } else if (this.mode === 'CAR') {
        return this.travelCompensation.commuteEnabledForCar
      } else {
        return this.travelCompensation.commuteEnabledForWalking
      }
    },
    emptyText () {
      return this.commuteEnabled
        ? this.$t('user_management.section.trip_registration.empty_text', { firstname: this.employeeFirstName })
        : this.$t(`user_management.section.trip_registration.not_configured_empty_text.${this.mode.toLowerCase()}`)
    }
  },
  methods: {
    async fetchData (withLoading = true) {
      if (!this.commuteEnabled) {
        return false
      }
      withLoading && this.TableLoader_show()
      try {
        await this.$api.compensations.getCommuteUserCompensation(
          {
            userOspId: this.userOspId,
            mobilityMode: this.mode
          },
          {
            onSuccess: response => {
              this.setData(response)
            }
          }
        )
      } catch (e) {
        console.error(e)
      }
      withLoading && this.TableLoader_hide()
      return true
    },
    async fetchTravelData () {
      const month = this.$date.local().month
      const year = this.$date.local().year

      try {
        await this.$api.travel.getTravelData(
          {
            userOspId: this.userOspId,
            month,
            year
          },
          {
            onSuccess: response => {
              this.setTravelData(response)
            }
          }
        )
      } catch (e) {
        console.error(e)
      }
    },
    dialogClose () {
      this.form.compensationPerDay = 0
      this.form.validFrom = null
      this.edit = false
      this.dialogKey = this.$key()
    },
    handleEdit ({ compensationPerDay, validFrom }) {
      this.form.compensationPerDay = compensationPerDay
      this.form.validFrom = validFrom
      this.edit = true
      this.dialogFormVisible = true
    },
    handleUpdate () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }
      const form = {
        ...this.form,
        mobilityMode: this.mode,
        userOspId: this.userOspId
      }
      return this.updateRequest(form)
    },
    handleDelete (row) {
      this.$confirm(
        this.$t(`user_management.section.trip_registration.commute.per_day.confirm_delete_${this.mode.toLowerCase()}.explanation`),
        this.$t(`user_management.section.trip_registration.commute.per_day.confirm_delete_${this.mode.toLowerCase()}.title`),
        confirmDefaultOptions
      )
        .then(() => {
          this.updateRequest({
            compensationPerDay: 0,
            validFrom: this.$date.local().toISODate(),
            mobilityMode: this.mode,
            userOspId: this.userOspId
          })
        })
        .catch(e => console.log(e))
    },
    updateRequest (form) {
      this.updating = true
      return this.$api.compensations.updateCommuteUserCompensation(form, {
        onSuccess: () => {
          this.dialogFormVisible = false
          this.fetchData()
        },
        onFinally: () => {
          this.updating = false
        }
      })
    },
    setData (data) {
      this.tableData = data
    },
    setTravelData (data) {
      this.travelData = data
    }
  },
  mounted () {
    this.fetchData()
    this.fetchTravelData()
  }
}
</script>

<style scoped></style>
