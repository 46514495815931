<template>
  <page-layout
    :title="$t('user_management.view_app_users_page.budgets.amount.title')"
    class="app-user-budget-amount"
    :has-back-button="true"
  >
    <template #header-actions>
      <base-button
        :label="$t('user_management.view_app_users_page.budgets.amount.add_button')"
        type="primary"
        :loading="saving"
        @click="handleSaveChanges"
      />
    </template>
    <el-alert
      class="info__alert"
      type="info"
      :closable="false"
      show-icon
    >
      <span v-html="$t('user_management.view_app_users_page.budgets.amount.alert_' + frequencyMethod)"/>
    </el-alert>
    <el-form
      ref="form"
      :model="form"
      label-position="top"
      @submit.prevent.native="null"
      autocomplete="off"
    >
      <base-select
        v-if="$auth.isOspAdmin()"
        :label="$t('general.label.type')"
        ref="transactionTypes"
        prop="transactionTypes"
        :items="transactionTypes"
        v-model="form.transactionType"
        name="transactionTypes"
        :max-width="400"
      />
      <base-currency-input
        :allow-negative="true"
        :label="$t('user_management.view_app_users_page.budgets.amount.additional_budget')"
        :max-width="400"
        prop="amount"
        name="amount"
        v-model="form.amount"
        :validations="$v.form.amount"
      />

      <base-input
        type="textarea"
        :label="$t('user_management.view_app_users_page.budgets.amount.description')"
        prop="description"
        name="description"
        :placeholder="$t('user_management.view_app_users_page.budgets.amount.description_placeholder')"
        v-model.trim="form.description"
        :validations="$v.form.description"
        maxlength="140"
        show-word-limit
        resize="none"
        :rows="4"
        :maxWidth="400"
        :required="true"
      />
    </el-form>
  </page-layout>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import PageLayout from '../layouts/PageLayout'
import { get } from 'lodash'

export default {
  name: 'AppUserBudgetAmountAdd',
  components: {
    PageLayout
  },
  props: ['budgetOspId'],
  data () {
    return {
      form: {
        transactionType: 'MANUAL_UPDATE',
        amount: 0,
        description: ''
      },
      saving: false,
      transactionTypes: [
        {
          value: 'MANUAL_UPDATE',
          label: this.$t('general.transaction_type.manual_update')
        },
        {
          value: 'TOPUP',
          label: this.$t('general.transaction_type.topup')
        }
      ]
    }
  },
  computed: {
    frequencyMethod () {
      return get(this.$route.query, 'frequencyMethod', 'MANUAL').toLowerCase()
    }
  },
  validations () {
    return {
      form: {
        amount: {
          required
        },
        description: {
          required,
          maxLength: maxLength(140)
        }
      }
    }
  },
  methods: {
    handleSaveChanges () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      this.saving = true
      this.$api.budget.addTransaction(
        {
          ...this.form,
          mobilityBudget: {
            ospId: this.budgetOspId
          }
        },
        {
          onSuccess: () => {
            this.$router.push({ name: 'AppUserBudgetView' })
          },
          onFinally: () => {
            this.saving = false
          }
        }
      ).catch((e) => {
        console.log(e)
        this.saving = false
      })
    }
  },
  async beforeRouteEnter (to, from, next) {
    if (!to.params.budgetOspId) {
      return to.params.userOspId
        ? next({ name: 'AppUserBudgetView', params: { userOspId: to.params.userOspId } })
        : next({ name: 'ManageAppUsers' })
    }
    next()
  }
}
</script>

<style lang="scss">
</style>
