<template>
  <page-layout
    :title="$t('user_management.view_app_users_page.edit_budget.title')"
    :has-back-button="true"
    :emit-back="true"
    @back="goBack"
  >
    <template #header-actions>
      <base-button
        :label="$t('general.button_label.save_changes')"
        type="primary"
        :loading="saving"
        @click="handleSaveChanges"
      />
    </template>
    <el-alert
      type="info"
      :closable="false"
      show-icon
    >
      <span v-html="$t('user_management.view_app_users_page.edit_budget.alert_text')"/>
    </el-alert>
    <el-form ref="form" :model="model" @submit.prevent.native="null" :key="key" autocomplete="off">
      <budget-item-section
        v-if="model"
        :budget="budgetType"
        :edit="true"
        v-model="model"
        :validations="$v.model"
      />
    </el-form>
  </page-layout>
</template>

<script>
import store from '@/store'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import PageLayout from '../layouts/PageLayout'
import BudgetItemSection from '../components/sections/BudgetItemSection'
import { confirmDefaultOptions } from '../app/helpers'
import ConfirmBeforeRouterLeave from '../mixins/ConfirmBeforeRouterLeave'

export default {
  name: 'AppUserBudgetEdit',
  components: {
    PageLayout,
    BudgetItemSection
  },
  mixins: [ConfirmBeforeRouterLeave],
  props: ['userOspId', 'budgetOspId'],
  data () {
    return {
      saving: false,
      model: null,
      dirty: false,
      key: ''
    }
  },
  computed: {
    ...mapGetters({
      userBudgets: 'budget/userBudgets',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    budgetTypes () {
      return this.corporateAccount.budgetTypes
    },
    allowedBudgetTypes () {
      return this.corporateAccount.allowedBudgetTypes
    },
    budget () {
      return this.userBudgets(this.userOspId).find(v => v.ospId === this.budgetOspId)
    },
    budgetType () {
      return this.budget.budgetType
    }
  },
  methods: {
    ...mapActions({
      createBudget: 'budget/createBudget'
    }),
    handleSaveChanges () {
      this.$v.model.$touch()
      if (this.$v.model.$invalid) {
        return false
      }
      this.saving = true
      this.model.save({
        successNotification: this.$t('user_management.view_app_users_page.edit_budget.success_notification'),
        onSuccess: () => {
          this.dirty = false
          this.$router.back()
        },
        onFinally: () => {
          this.saving = false
        }
      })
    },
    async allowRouterLeave () {
      if (!this.dirty) {
        return true
      }
      try {
        await this.$confirm(
          this.$t('user_management.view_app_users_page.edit_budget.confirm_exit_explanation'),
          this.$t('user_management.view_app_users_page.edit_budget.confirm_exit_title'), {
            ...confirmDefaultOptions,
            confirmButtonText: this.$t('user_management.view_app_users_page.edit_budget.confirm_exit_yes'),
            cancelButtonText: this.$t('user_management.view_app_users_page.edit_budget.confirm_exit_no')
          }
        )
        this.dirty = false
        return true
      } catch (e) {
        return false
      }
    }
  },
  validations () {
    return {
      model: {
        frequencyAmount: {
          required
        }
      }
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      this.dirty = false
      this.model = cloneDeep(this.budget)
      this.key = this.$key()
      setTimeout(() => {}, 100)
      this.dirty = false
      this.$watch('model', {
        deep: true,
        handler () {
          this.dirty = true
        }
      })
    })
  },
  async beforeRouteEnter (to, from, next) {
    const userOspId = to.params.userOspId
    if (userOspId && !store.getters['budget/userBudgets'](userOspId).length) {
      try {
        await store.dispatch('budget/getUserBudgets', { ospId: userOspId })
      } catch (e) {
        if (e.getStatus() !== 404) {
          throw e
        }
      }
    }
    next()
  }
}
</script>

<style lang="scss">
</style>
