<template>
  <base-section class="timeline" :title="$t('dashboard.timeline.history_select.label')">
    <el-form
      ref="form"
      :model="form"
      autocomplete="off"
      id="timeline"
      :inline="true"
      @submit.prevent.native="null"
    >
      <el-row type="flex" justify="space-between" align="bottom">
        <base-select
          v-model="form.type"
          ref="type"
          prop="type"
          :items="selectOptions"
          name="type"
        ></base-select>
        <base-datepicker
          v-model="daterange"
          type="monthrange"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          valueFormat="yyyyMM"
          format="MMMM yyyy"
          :maxWidth="300"
          :clearable="false"
          :picker-options="dateOptions"
          style="margin-right: 0"
          :key="key"
        >
        </base-datepicker>
      </el-row>
    </el-form>
    <v-chart
      class="timeline-chart"
      :autoresize="true"
      :options="charOptions"
    />
  </base-section>

</template>

<script>

import 'echarts/lib/chart/line'
import { sortBy, groupBy, sumBy, has } from 'lodash'
import { Interval } from 'luxon'
import Timeline from '@/mixins/charts/Timeline'

export default {
  name: 'AppUserBudgetTimeline',
  props: ['userOspId'],
  mixins: [Timeline],
  data () {
    return {
      form: {
        type: 0
      },
      key: '',
      daterange: [
        this.$date.local().minus({ month: 11 }).toFormat('yyyyMM'),
        this.$date.local().toFormat('yyyyMM')
      ]
    }
  },
  computed: {
    dateOptions () {
      return {
        disabledDate: value => {
          return value > this.$date.local().endOf('day').toJSDate()
        }
      }
    },
    selectOptions () {
      return [
        {
          value: 0,
          label: this.$t('dashboard.timeline.history_select.options.transactions')
        },
        {
          value: 1,
          label: this.$t('dashboard.timeline.history_select.options.amount_of_expenses')
        }
      ]
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.fetchData()
      }
    },
    daterange: {
      deep: true,
      immediate: true,
      handler () {
        this.key = this.$key()
        this.fetchData()
      }
    }
  },
  methods: {
    fetchData () {
      if (this.form.type === 0) {
        this.fetchCountBooking()
      } else if (this.form.type === 1) {
        this.fetchSumEvents()
      }
    },
    fetchCountBooking () {
      const form = {
        monthFrom: this.daterange[0],
        monthTo: this.daterange[1],
        parentOspId: this.userOspId
      }
      this.$api.reporting.countBookings(form, {
        onSuccess: response => {
          this.setData(response)
        }
      })
    },
    fetchSumEvents () {
      const form = {
        monthFrom: this.daterange[0],
        monthTo: this.daterange[1],
        parentOspId: this.userOspId
      }
      this.$api.reporting.sumEvents(form, {
        onSuccess: response => {
          this.setData(response, v => v.totalPriceVatIncl, value => Number(value).toFixed(2))
        }
      })
    },
    setData (response, sumByCb = v => v.count, formatter = v => v) {
      this.charData.seriesData = []
      this.charData.categoriesData = this.getDateRange()
      const data = groupBy(sortBy(response, 'billingMonth'), 'billingMonth')
      this.charData.categoriesData.forEach(month => {
        let value = 0
        if (has(data, month)) {
          value = sumBy(data[month], sumByCb) || 0
        }
        value = formatter(value)
        this.charData.seriesData.push(value)
      })
      if (this.charData.categoriesData.length === 1) {
        this.charData.categoriesData = ['', this.charData.categoriesData[0], '']
        this.charData.seriesData = [this.charData.seriesData[0], this.charData.seriesData[0], this.charData.seriesData[0]]
      }
    },
    getDateRange () {
      const startDate = this.$date.fromFormat(this.daterange[0], 'yyyyMM')
      const endDate = this.$date.fromFormat(this.daterange[1], 'yyyyMM')
      const interval = Interval.fromDateTimes(startDate, endDate)
      let cursor = interval.start.startOf('day')
      const range = []
      while (cursor <= interval.end) {
        range.push(cursor.toFormat('yyyyMM'))
        cursor = cursor.plus({ month: 1 })
      }
      return range
    }
  }
}
</script>
