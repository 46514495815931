import Model from './Model'
import { DateTime } from 'luxon'
import { currency, DATE_FORMAT } from '@/app/helpers'
import i18n from '@/i18n'

class CompanyTransaction extends Model {
  constructor (object = {}) {
    super(object)
  }

  // amount:-4
  // bookedFor:"2021-02-05T13:06:21+0000"
  // createdBy:Object
  // ospId:"OlympusMobility.CORPORATE_ACCOUNT.OlympusMobility.USER.vouchersvc"
  // createdOn:"2021-02-05T13:06:21+0000"
  // currency:"EUR"
  // description:"331207|6789"
  // eventOspId:"Velo.EVENT.bbebf132-3415-49c4-bce1-a799cf1700be"
  // mobilityBudget:Object
  // ospId:"OlympusMobility.CORPORATE_ACCOUNT.uran.BUDGET.COMPANY_BUDGET.budget"
  // transactionType:"EVENT"
  // transportMode:"BIKE"

  get mobilityMode () {
    return this.transportMode
  }

  get dateTime () {
    return DateTime.fromISO(this.bookedFor).toFormat(DATE_FORMAT + ' ' + 'HH\'h\'mm')
  }

  get date () {
    return DateTime.fromISO(this.bookedFor).toFormat(DATE_FORMAT)
  }

  get time () {
    return DateTime.fromISO(this.bookedFor).toFormat('HH\'h\'mm')
  }

  get currencyAmount () {
    return (this.amount < 0 ? '- ' : '') + currency(Math.abs(this.amount))
  }

  get transactionTypeLabel () {
    return this.transactionType ? i18n.t(`general.transaction_type.${this.transactionType.toLowerCase()}`) : null
  }
}

export default CompanyTransaction
